import {
  ElementProps,
  FileInput,
  FileInputProps,
  Flex,
  Input,
  ScrollArea,
  Stack,
  ThemeIcon,
  UnstyledButton,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { Plus } from '@phosphor-icons/react';
import { ImageMiniature, imageMiniTestIds, ImageUploadDrawer, imageUploadTestIds } from 'components/Image';

interface SupportMediaInputProps extends Omit<FileInputProps, 'value'>, ElementProps<'input', keyof FileInputProps> {
  value?: File[];
  onUpload: (files: File[]) => void;
  onDelete: (index: number) => void;
}

export const testIds = {
  input: 'support-form-media',
  button: 'support-form-media-button',
  getImageTestId: imageMiniTestIds.getWrapperTestId,
  upload: imageUploadTestIds,
};

const LIMIT = 5;

export const SupportMediaInput = ({ value = [], onUpload, onDelete, ...props }: SupportMediaInputProps) => {
  const { t } = useTranslation();
  const [photoDrawerOpened, { open: openPhotoDrawer, close: closePhotoDrawer }] = useDisclosure();

  const label = value.length
    ? `${t('supportForm.form.media')} (${value.length}/${LIMIT})`
    : t('supportForm.form.media');
  const canAdd = value.length < LIMIT;

  return (
    <Input.Wrapper label={label}>
      <ScrollArea w='100%'>
        <Flex gap='xs' wrap='nowrap'>
          {canAdd && (
            <UnstyledButton disabled={!canAdd} onClick={openPhotoDrawer} data-testid={testIds.button}>
              <Stack
                w={100}
                h={100}
                bg='cool.1'
                className='border-dashed rounded-sm border-default'
                justify='center'
                c='black'
                align='center'
                gap='zero'
              >
                <ThemeIcon variant='subtle' color='black'>
                  <Plus />
                </ThemeIcon>
                {t('common.add')}
              </Stack>
            </UnstyledButton>
          )}
          {[...value].reverse().map((file, index) => (
            <ImageMiniature
              key={file.name}
              id={file.name}
              image={URL.createObjectURL(file)}
              onDelete={() => onDelete(index)}
            />
          ))}
        </Flex>
      </ScrollArea>
      <FileInput hidden {...props} data-testid={testIds.input} />
      <ImageUploadDrawer opened={photoDrawerOpened} maxFiles={LIMIT} onClose={closePhotoDrawer} onUpload={onUpload} />
    </Input.Wrapper>
  );
};
