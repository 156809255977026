import { Text, Stack, ScrollArea, Center, ThemeIcon } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import { FlowId, Flow } from '@flow/flow-backend-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { useFlowsByFolder, useFlowStore } from 'stores/flow';
import { EmptyState } from 'components';
import { OngoingFormCarousel, BaseCardLayout, FlowDrawer } from 'pages/HomePage/components';
import { LogoLoopSingle } from 'assets';

export const testIds = {
  container: 'forms-tab-container',
  skeleton: 'forms-tab-skeleton',
  emptyState: 'forms-tab-empty',
  loader: 'forms-tab-loader',
  support: 'forms-tab-support',
  getFlowId: (id: FlowId) => `forms-tab-flow-${id}`,
};

export const FormsTabContent = () => {
  const { spyClick } = useSpy();
  const { t } = useTranslation();
  // const { distract: openSupport } = useDistraction('support-form');
  const { isLoadingFlows } = useFlowStore(['isLoadingFlows']);
  const flows = useFlowsByFolder();
  const [selectedFlow, setSelectedFlow] = useState<Flow>();

  const handleOpen = (flow: Flow) => () => {
    spyClick(names.HomePage.SelectForm, { flowId: flow.id, flowName: flow.name });
    setSelectedFlow(flow);
  };

  // const onClickSupport = () => {
  //   spyClick(names.HomePage.Support);
  //   openSupport();
  // };

  const resetSelectedFlow = () => setSelectedFlow(undefined);

  return (
    <>
      <ScrollArea type='scroll' h='100%' data-testid={testIds.container}>
        <OngoingFormCarousel />
        <Stack gap='tn' px='tn' mt={44} mb='lg'>
          <>
            {!flows.length && isLoadingFlows ? (
              <Center>
                <ThemeIcon size={70} variant='transparent' c='emerald.9'>
                  <LogoLoopSingle className='animate-spin' width={70} height='auto' data-testid={testIds.loader} />
                </ThemeIcon>
              </Center>
            ) : (
              <EmptyState
                mt={68}
                hidden={Boolean(flows.length)}
                title={t('home.emptyState.formsListTitle')}
                data-testid={testIds.emptyState}
              >
                {/* <Button
                  variant='outline'
                  mt={48}
                  h={50}
                  fw='normal'
                  onClick={onClickSupport}
                  data-testid={testIds.support}
                >
                  {t('home.support')}
                </Button> */}
              </EmptyState>
            )}
            {flows.map((flow) => (
              <BaseCardLayout
                mih={76}
                key={flow.id}
                id={flow.id}
                py='sm'
                title={
                  <Text size='lg' lh='md' lineClamp={2} c='cool.7'>
                    {flow.name}
                  </Text>
                }
                subtitle={
                  flow.description && (
                    <Text size='sm' mt={4} lineClamp={1} c='cool.4'>
                      {flow.description}
                    </Text>
                  )
                }
                rightSection={<CaretRight size={20} />}
                onClick={handleOpen(flow)}
                data-testid={testIds.getFlowId(flow.id)}
              />
            ))}
          </>
        </Stack>
      </ScrollArea>
      <FlowDrawer flow={selectedFlow} opened={Boolean(selectedFlow)} onClose={resetSelectedFlow} />
    </>
  );
};
