import { Chip as MantineChip, Flex, Stack, Title } from '@mantine/core';
import { Chip } from 'components';
import { useTranslation } from 'react-i18next';
import { HistoryFilterGroupKey, HistoryFilterItem } from 'stores/flow';

export const testIds = {
  filterTitle: 'history-filter-title',
  filterContainer: 'history-filter-container',
  getFilterItemTestId: (filterId: string) => `history-filter-item-${filterId}`,
  filterRegex: /^history-filter-item-/,
};

interface HistoryFilterGroupProps {
  type: HistoryFilterGroupKey;
  filters: HistoryFilterItem[];
  values: string[];
  onFilterChange: (values: string[]) => void;
}

export const HistoryFilterGroup = ({ type, filters, values, onFilterChange }: HistoryFilterGroupProps) => {
  const { t } = useTranslation();

  return (
    <Stack gap='sm' pt='tn' mb='md'>
      <Title size='md' c='cool.6' fw={600} data-testid={testIds.filterTitle}>
        {t(`home.filters.${type}.title`)}
      </Title>
      <Flex gap='tn' wrap='wrap' data-testid={testIds.filterContainer}>
        {/* // TODO: use `Chip.Group` from our components folder when merged with "inspection page" epic */}
        <MantineChip.Group multiple value={values} onChange={onFilterChange}>
          {filters.map((filter) => (
            <Chip
              key={type + filter.value}
              size='lg'
              value={filter.value}
              data-testid={testIds.getFilterItemTestId(filter.value)}
            >
              {t(`home.filters.${type}.${filter.value}`, { defaultValue: filter.value })}
            </Chip>
          ))}
        </MantineChip.Group>
      </Flex>
    </Stack>
  );
};
