import { Box } from '@mantine/core';
import { useMemo } from 'react';
import { themeV2 } from 'assets';
import { PageProvider } from 'providers/page.provider';
import { names, useSpy, useSpyMount } from 'services/espionage';
import { AppMenuDrawer, LanguageSelectDrawer, DateFormatSelectDrawer, SupportDrawer } from 'components';
import { useParams } from 'react-router-dom';
import { ExecutionRouteParams } from 'routes/routes.config';
import { getThemeCircleStyles } from 'utils';
import { useExecution } from 'stores/flow';
import {
  ReviewDrawer,
  reviewTestIds,
  ContainerList,
  containerListTestIds,
  InspectionEmptyState,
  InspectionHeaderWrapper,
  InspectionHeaderWrapperTestIds,
  InspectionToolbar,
  toolbarTestIds,
  InspectionLoader,
  loaderTestIds,
} from './components';
import { useExecutionBlockedModal, useStartInspection } from './InspectionPage.hooks';
import { BG_RING_POSITIONS } from './InspectionPage.consts';
import classes from './InspectionPage.module.css';

export const testIds = {
  rootContainerList: containerListTestIds.getWrapperTestId(),
  headerWrapper: InspectionHeaderWrapperTestIds,
  toolbar: toolbarTestIds,
  review: reviewTestIds,
  loader: loaderTestIds,
};

export const InspectionPage = () => {
  const { spyRef } = useSpy();
  const { executionId } = useParams() as ExecutionRouteParams;
  const execution = useExecution(executionId);
  const loading = useStartInspection(executionId);
  useSpyMount(spyRef, names.ExecutionPage.self);

  useExecutionBlockedModal(execution);

  const styles = useMemo(() => getThemeCircleStyles(BG_RING_POSITIONS), []);

  return (
    <PageProvider theme={themeV2}>
      <Box style={styles} className={classes.themeBackground} />
      <InspectionHeaderWrapper />
      <InspectionEmptyState />
      <ContainerList px='tn' pb='lg' mb={80} />
      <InspectionToolbar />
      <AppMenuDrawer />
      <LanguageSelectDrawer />
      <DateFormatSelectDrawer />
      <SupportDrawer />
      <ReviewDrawer />
      <InspectionLoader visible={loading} />
    </PageProvider>
  );
};
