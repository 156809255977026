import { useMemo } from 'react';
import { ExecutionId, Flow, FlowId, ExecutionStatus } from '@flow/flow-backend-types';
import { exists } from 'utils';
import { useCurrentUser } from 'stores/auth';
import { HISTORY_FILTER_DEFAULT_VALUE } from 'consts';
import { useFlowStore } from './flow.store';
import { Execution, HistoryFilterValues, TitledExecution } from './flow.types';
import {
  sortByExecutionCreationDate,
  sortByExecutionFinishDate,
  filterOngoingExecutionsByUser,
  filterCompletedExecutions,
  filterExecutionsByStatuses,
  filterExecutionsByUser,
  filterExecutionsByFinishedDate,
} from './flow.utils';

export const useFlow = (flowId: string): Flow | undefined => {
  const { flows } = useFlowStore(['flows']);
  return flows[flowId];
};

export const useExecution = (executionId: string): Execution | undefined => {
  const { executions } = useFlowStore(['executions']);
  return executions[executionId];
};

export const useFlowByExecutionId = (executionId: string): Flow | undefined => {
  const execution = useExecution(executionId);
  return useFlow(execution?.flowRef.id ?? '');
};

export function useMetadataExposedFields(executionId: ExecutionId): string[] {
  const execution = useExecution(executionId);
  if (!execution) return [];
  return execution.preInspectionMetadata?.map(({ value, exposed }) => (exposed ? value : null)).filter(exists) ?? [];
}

export const useSingleInstanceValidator = () => {
  const { executions, getFlowById } = useFlowStore(['executions', 'getFlowById']);
  return (flowId: string) => {
    const flow = getFlowById(flowId);
    if (flow.singleInstance) {
      const hasInProgressExecutions = Object.values(executions).some(
        (execution) => execution.flowRef.id === flowId && execution.status === ExecutionStatus.inProgress,
      );
      return !hasInProgressExecutions;
    }
    return true;
  };
};

export const useParticipantLimitValidator = () => {
  const { getFlowById } = useFlowStore(['getFlowById']);
  return (flowId: FlowId, participantLength: number) => {
    const { maxInspectors } = getFlowById(flowId);
    return maxInspectors ? participantLength >= maxInspectors : false;
  };
};

export const useMyOngoingExecutions = (): TitledExecution[] => {
  const currentUser = useCurrentUser();
  const { executions, flows } = useFlowStore(['executions', 'flows']);

  return useMemo(() => {
    const ongoingExecutions = filterOngoingExecutionsByUser(Object.values(executions), currentUser?.userId);
    const ongoingExecutionsWithFlow = ongoingExecutions.filter(({ flowRef }) => flows[flowRef.id]);
    return sortByExecutionCreationDate(ongoingExecutionsWithFlow).map((execution) => ({
      ...execution,
      title: flows[execution.flowRef.id]?.name,
    }));
  }, [executions, currentUser, flows]);
};

export const useHistoryFilteredExecutions = (filterValues: HistoryFilterValues) => {
  const { statuses, periods, executedBy } = filterValues;
  const currentUser = useCurrentUser();
  const { executions, flows } = useFlowStore(['executions', 'flows']);

  return useMemo(() => {
    let filteredExecutions = filterCompletedExecutions(Object.values(executions));

    if (!statuses.includes(HISTORY_FILTER_DEFAULT_VALUE)) {
      filteredExecutions = filterExecutionsByStatuses(filteredExecutions, statuses as ExecutionStatus[]);
    }

    if (!periods.includes(HISTORY_FILTER_DEFAULT_VALUE)) {
      filteredExecutions = filterExecutionsByFinishedDate(filteredExecutions, periods);
    }

    if (!executedBy.includes(HISTORY_FILTER_DEFAULT_VALUE)) {
      filteredExecutions = filterExecutionsByUser(filteredExecutions, currentUser?.userId);
    }

    const filteredExecutionsWithFlow = filteredExecutions.filter(({ flowRef }) => flows[flowRef.id]);
    return sortByExecutionFinishDate(filteredExecutionsWithFlow).map((execution) => ({
      ...execution,
      title: flows[execution.flowRef.id]?.name,
    }));
  }, [executions, filterValues]);
};

export const useCompletedExecutions = (): TitledExecution[] => {
  const { executions, flows } = useFlowStore(['executions', 'flows']);

  return useMemo(() => {
    const completedExecutions = filterCompletedExecutions(Object.values(executions));
    return sortByExecutionFinishDate(completedExecutions).map((execution) => ({
      ...execution,
      title: flows[execution.flowRef.id]?.name,
    }));
  }, [executions, flows]);
};

export const useFlowsByFolder = () => {
  const { flows } = useFlowStore(['flows']);

  return useMemo(() => {
    const grouped = Object.values(flows ?? {}).reduce<Record<string, Flow[]>>((acc, flow) => {
      const folderId = flow.folderId ?? 'null';
      if (!acc[folderId]) {
        acc[folderId] = [];
      }
      acc[folderId].push(flow);
      return acc;
    }, {});

    return Object.entries(grouped)
      .sort(([keyA], [keyB]) => {
        if (keyA === 'null') return -1;
        if (keyB === 'null') return 1;
        return 0;
      })
      .flatMap(([_, group]) => group.sort((a, b) => a.order - b.order));
  }, [flows]);
};

export const useOngoingExecutionsByFlow = (flowId?: FlowId): Execution[] => {
  const { executions } = useFlowStore(['executions']);
  return useMemo(() => {
    const ongoingExecutions = Object.values(executions).filter(
      ({ flowRef, status }) => flowRef.id === flowId && status === ExecutionStatus.inProgress,
    );
    return sortByExecutionCreationDate(ongoingExecutions);
  }, [executions, flowId]);
};

export function useIsVoiceInputEnabled(executionId: ExecutionId): boolean {
  const flow = useFlowByExecutionId(executionId);

  return Boolean(flow?.voiceInputEnabled);
}
