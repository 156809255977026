import { Drawer, drawerTestIds } from 'components';
import { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInput, rem } from '@mantine/core';
import { isAndroid } from 'react-device-detect';
import { parseNumberIfValid } from 'utils';
import { getHotkeyHandler } from '@mantine/hooks';
import { EventValidationIcon } from '../../../EventBase';
import classes from './NumericEventInput.module.css';

export const testIds = {
  header: 'numeric-event-drawer-header',
  input: 'numeric-event-drawer-input',
  drawerTestIds,
};

interface NumericEventDrawerProps extends Pick<ComponentProps<typeof Drawer>, 'opened'> {
  title: string;
  placeHolder?: string;
  decimalPrecision?: number;
  value?: number;
  valid?: boolean;
  bounded?: boolean;
  onEditing: () => void;
  onDrawerClosed: () => void;
  onFinishedEditing: (finalValue?: number) => void;
}

export const NumericEventDrawer = ({
  opened,
  value,
  title,
  placeHolder,
  decimalPrecision,
  valid,
  bounded,
  onEditing,
  onFinishedEditing,
  onDrawerClosed,
}: NumericEventDrawerProps) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<number | undefined>(value);

  useEffect(() => {
    if (!isEditing) {
      setCurrentValue(value);
    }
  }, [value]);

  const closeDrawer = () => {
    onDrawerClosed();
  };

  const startEditing = () => {
    setIsEditing(true);
    onEditing();
  };

  const finishEditing = () => {
    setIsEditing(false);
    onFinishedEditing(currentValue);
  };

  const changeValue = (newValue: string | number) => {
    setCurrentValue(parseNumberIfValid(String(newValue)));
  };

  return (
    <Drawer opened={opened} onClose={closeDrawer} trapFocus={false}>
      <Drawer.Header
        withCloseButton
        leftSection={<EventValidationIcon bounded={bounded} valid={valid} size={24} h='100%' py='auto' />}
        data-testid={testIds.header}
      >
        {title}
      </Drawer.Header>
      <Drawer.Body>
        <NumberInput
          classNames={classes}
          styles={{ input: { fontSize: rem(32) } }}
          allowDecimal
          maxLength={25}
          decimalSeparator=','
          value={currentValue ?? ''}
          decimalScale={decimalPrecision}
          inputMode={isAndroid ? 'numeric' : undefined}
          placeholder={placeHolder ?? t('placeholders.number')}
          error={bounded === false}
          onFocus={startEditing}
          onBlur={finishEditing}
          onChange={changeValue}
          onKeyDown={getHotkeyHandler([['Enter', finishEditing]])}
          data-testid={testIds.input}
        />
      </Drawer.Body>
      <Drawer.Footer h={80} />
    </Drawer>
  );
};
