import { Drawer, drawerTestIds } from 'components';
import { ComponentProps, useMemo } from 'react';
import { Center } from '@mantine/core';
import { DatePicker, DatePickerProps, DateValue } from '@mantine/dates';
import { EventValidationIcon } from '../../../EventBase';

export const testIds = {
  dateDrawerHeader: 'date-event-drawer-header',
  dateEventDrawerDatepicker: 'date-event-drawer-datepicker',
  drawerTestIds,
};

interface DateTextEventDrawerProps extends Pick<ComponentProps<typeof Drawer>, 'opened'> {
  title: string;
  value?: number;
  valid?: boolean;
  bounded?: boolean;
  onDrawerClosed: () => void;
  onValuePicked: (finalValue?: number) => void;
}

export const DateEventDrawer = ({
  opened,
  value,
  title,
  valid,
  bounded,
  onDrawerClosed,
  onValuePicked,
}: DateTextEventDrawerProps) => {
  const dateValue = useMemo(() => (value ? new Date(value) : null), [value]);

  const onChange = (selectedDate: DateValue) => {
    onValuePicked(selectedDate?.getTime());
  };

  const getDayProps: DatePickerProps['getDayProps'] = (date) => {
    if (date.getDate() === dateValue?.getDate() && bounded === false) {
      return {
        style: {
          backgroundColor: 'var(--mantine-color-red-filled)',
          color: 'var(--mantine-color-white)',
        },
      };
    }

    return {};
  };

  return (
    <Drawer height='32rem' opened={opened} onClose={onDrawerClosed}>
      <Drawer.Header
        withCloseButton
        leftSection={<EventValidationIcon bounded={bounded} valid={valid} size={24} h='100%' py='auto' />}
        data-testid={testIds.dateDrawerHeader}
      >
        {title}
      </Drawer.Header>
      <Drawer.Body>
        <Center w='100%'>
          <DatePicker
            size='md'
            allowDeselect
            getDayProps={getDayProps}
            c={bounded === false ? 'red' : undefined}
            defaultDate={dateValue ?? undefined}
            value={dateValue}
            onChange={onChange}
            data-testid={testIds.dateEventDrawerDatepicker}
          />
        </Center>
      </Drawer.Body>
    </Drawer>
  );
};
