import { Text, Stack, ScrollArea, ThemeIcon } from '@mantine/core';
import { CastleTurret } from '@phosphor-icons/react';
import { ExecutionId } from '@flow/flow-backend-types';
import { useState } from 'react';
import { isAndroid } from 'react-device-detect';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { ThemeVariant } from 'interfaces';
import { openPdfInNewTab } from 'utils';
import {
  getMetadataExposedFields,
  FinishedExecutionStatuses,
  HistoryFilterValues,
  useHistoryFilteredExecutions,
  HistoryFilterGroupKey,
} from 'stores/flow';
import { useUserDateFormat } from 'stores/settings';
import { EmptyState, PdfPreviewDrawer } from 'components';
import { HISTORY_FILTER_DEFAULT_VALUE } from 'consts';
import { BaseCardLayout } from '..';
import { HistoryFilterDrawer, testIds as filterDrawerTestIds } from '../HistoryFilterDrawer/HistoryFilterDrawer';
import { HistoryFilterPanel, testIds as filterPanelTestIds } from '../HistoryFilterPanel/HistoryFilterPanel';
import { HistoryCardTitle } from './HistoryCardTitle';

interface HistoryTabContentProps {
  filterValues: HistoryFilterValues;
  onFilterChange: (values: Partial<HistoryFilterValues>) => void;
}

export const testIds = {
  container: 'history-tab-container',
  emptyState: 'history-tab-empty',
  getExecutionId: (id: ExecutionId) => `history-tab-execution-${id}`,
  filterPanel: filterPanelTestIds,
  filterDrawer: filterDrawerTestIds,
};

const statusVariantsMap: Record<FinishedExecutionStatuses, ThemeVariant> = {
  done: 'success',
  pending: 'warning',
  expired: 'danger',
  cancelled: 'default',
};

export const HistoryTabContent = ({ filterValues, onFilterChange }: HistoryTabContentProps) => {
  const { t } = useTranslation();
  const dateFormat = useUserDateFormat();
  const [opened, { open, close }] = useDisclosure(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfFilename, setPdfFilename] = useState('');
  const executions = useHistoryFilteredExecutions(filterValues);
  const filtersApplied = Object.values(filterValues).some((values) => !values.includes(HISTORY_FILTER_DEFAULT_VALUE));
  const filteredCount = executions.length;

  const handleFilterChange = (key: HistoryFilterGroupKey, values: string[]) => {
    onFilterChange({ [key]: values });
  };

  const onFiltersApply = (values: HistoryFilterValues) => {
    onFilterChange(values);
  };

  const showPreview = (url: string, fileName: string) => {
    if (isAndroid) {
      setPdfFilename(fileName);
      setPdfUrl(url);
    } else {
      openPdfInNewTab(url, fileName);
    }
  };

  return (
    <>
      <Stack gap='xs' pt='lg' px='tn' h='100%' data-testid={testIds.container}>
        <HistoryFilterPanel filteredCount={filteredCount} filtersApplied={filtersApplied} onFiltersClick={open} />
        <ScrollArea type='scroll' className='grow-1'>
          <Stack mb='lg' gap='tn'>
            <EmptyState
              mt={100}
              hidden={Boolean(filteredCount)}
              icon={
                <ThemeIcon variant='subtle' size={48} color='cool.4'>
                  <CastleTurret size={48} weight='duotone' />
                </ThemeIcon>
              }
              title={t('home.emptyState.historyTitle')}
              data-testid={testIds.emptyState}
            />
            {executions.map((execution) => (
              <BaseCardLayout
                key={execution.id}
                id={execution.id}
                title={execution.title && <HistoryCardTitle execution={execution} onShowPreview={showPreview} />}
                subtitle={
                  getMetadataExposedFields(execution)[0] && (
                    <Text size='sm' mb='tn'>
                      {getMetadataExposedFields(execution)[0]}
                    </Text>
                  )
                }
                participants={execution.joinedUsers}
                metadata={dayjs(execution.finishedAt).format(`HH:mm • ${dateFormat}`)}
                status={t(`home.historyStatuses.${execution.status as FinishedExecutionStatuses}`)}
                statusVariant={statusVariantsMap[execution.status as FinishedExecutionStatuses]}
                data-testid={testIds.getExecutionId(execution.id)}
              />
            ))}
          </Stack>
        </ScrollArea>
      </Stack>
      <HistoryFilterDrawer
        opened={opened}
        filterValues={filterValues}
        filteredCount={filteredCount}
        onClose={close}
        onFilterChange={handleFilterChange}
        onFiltersApply={onFiltersApply}
      />
      <PdfPreviewDrawer url={pdfUrl} filename={pdfFilename} onBack={() => setPdfUrl('')} />
    </>
  );
};
