export const ROUTES = {
  BACK: -1,
  ROOT: '/',
  LOGIN: '/login',
  HOME: '/home',
  INSPECTION: (executionId = ':executionId') => `/inspection/exec/${executionId}`,
  PRE_INSPECTION: (flowId = ':flowId') => `/inspection/pre/${flowId}}`,
  POST_INSPECTION: (executionId = ':executionId') => `/inspection/post/${executionId}`,
  /** @deprecated */
  REVIEW_INSPECTION: (executionId = ':executionId') => `/inspection/review/${executionId}`,
} as const;

export type ExecutionRouteParams = { executionId: string };

export type FlowRouteParams = { flowId: string };
