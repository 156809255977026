import { Flex, Input, ScrollArea, Skeleton } from '@mantine/core';
import { names, useSpy } from 'services/espionage';
import { usePhotoContext } from 'contexts/ItemPhotoContext';
import { useOnline } from 'stores/network';
import { ImageMiniature } from '../../../../../../components/Image/ImageMiniature';

export const testIds = {
  wrapper: 'image-event-wrapper',
  loadingMiniature: 'image-event-loading-miniature',
};

export const ImageEvent = () => {
  const { spyClick } = useSpy();
  const {
    imageData,
    deleteImage,
    openEditor,
    loadingNewReport,
    loadingUpload,
    containerId,
    bulkUploadCounter,
    bulkUploadTotal,
  } = usePhotoContext();
  const online = useOnline();
  const showSkeleton = loadingNewReport || loadingUpload;
  const showContent = imageData.length > 0 || showSkeleton;

  const onEdit = (imageId: string) => {
    spyClick(names.Container.EditPhoto, { containerId, fileId: imageId });
    openEditor(imageId);
  };

  const onDelete = (imageId: string) => {
    spyClick(names.Container.DeletePhoto, { containerId, fileId: imageId });
    deleteImage(imageId);
  };

  return (
    <Input.Wrapper mt={8} hidden={!showContent}>
      <ScrollArea>
        <Flex direction='row-reverse' justify='flex-end' gap='tn' w='100%' data-testid={testIds.wrapper}>
          {imageData.map((image) => (
            <ImageMiniature
              id={image.id}
              key={image.id}
              image={image.editedBase64 || image.originalBase64}
              onDelete={() => onDelete(image.id)}
              onEdit={() => onEdit(image.id)}
              disabled={loadingUpload || !online}
            />
          ))}
          {showSkeleton &&
            [...Array(bulkUploadTotal - bulkUploadCounter || 1).keys()].map((i) => (
              <Skeleton
                key={i}
                className='shrink-0'
                w={100}
                h={100}
                radius='md'
                data-testid={testIds.loadingMiniature}
              />
            ))}
        </Flex>
      </ScrollArea>
    </Input.Wrapper>
  );
};
