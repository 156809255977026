import { ComponentProps, useEffect, useState } from 'react';
import { Center, Text } from '@mantine/core';
import cn from 'classnames';
import Picker, { PickerValue } from 'react-mobile-picker';
import { isDesktop } from 'react-device-detect';
import { getValueFromFormattedTime } from 'utils';
import { Drawer, drawerTestIds } from 'components';
import { HOURS24_OPTIONS, MINUTES_OPTIONS } from 'consts';
import { EventValidationIcon } from '../../../EventBase';
import classes from './TimeEvent.module.css';

export const testIds = {
  drawerHeader: 'time-event-drawer-header',
  drawerInput: 'time-event-drawer-input',
  drawerTestIds,
  picker: 'time-event-picker',
  pickerHours: 'time-event-picker-hours',
  pickerMinutes: 'time-event-picker-minutes',
  pickerPeriod: 'time-event-picker-period',
  getPickerItem: (value: string) => `time-event-picker-item-${value}`,
  pickerSelectedItem: 'time-event-picker-selected-item',
};

interface TimeEventDrawerProps extends Pick<ComponentProps<typeof Drawer>, 'opened'> {
  title: string;
  value?: string;
  valid?: boolean;
  bounded?: boolean;
  onEditing: () => void;
  onDrawerClosed: () => void;
  onFinishedEditing: (finalValue?: number | null) => void;
}

export const DEFAULT_PICKER_OPTION = '--';
const DEFAULT_PICKER_VALUES = `${DEFAULT_PICKER_OPTION}:${DEFAULT_PICKER_OPTION}`;

export const TimeEventDrawer = ({
  opened,
  value,
  title,
  valid,
  bounded,
  onEditing,
  onFinishedEditing,
  onDrawerClosed,
}: TimeEventDrawerProps) => {
  const [hours, minutes] = (value || DEFAULT_PICKER_VALUES).split(':');
  const [pickerValue, setPickerValue] = useState<PickerValue>({
    hours,
    minutes,
  });
  const hoursOptions = value ? HOURS24_OPTIONS : [DEFAULT_PICKER_OPTION, ...HOURS24_OPTIONS];
  const minutesOptions = value ? MINUTES_OPTIONS : [DEFAULT_PICKER_OPTION, ...MINUTES_OPTIONS];

  const finishEditing = (newValue: PickerValue) => {
    const timeValue = getValueFromFormattedTime(`${newValue.hours}:${newValue.minutes}`);
    onFinishedEditing(timeValue);
  };

  const onPickerChange = (newValue: PickerValue) => {
    onEditing();
    setPickerValue(newValue);
    finishEditing(newValue);
  };

  const renderOption = (option: string) => (
    <Picker.Item key={option} value={option} data-testid={testIds.getPickerItem(option)}>
      {({ selected }) => (
        <Text
          className={cn(classes.pickerItem, {
            [classes.selected]: selected,
            [classes.draft]: !value && selected,
            [classes.error]: bounded === false,
          })}
          data-testid={selected ? testIds.pickerSelectedItem : undefined}
        >
          {option}
        </Text>
      )}
    </Picker.Item>
  );

  useEffect(() => {
    setPickerValue({ hours, minutes });
  }, [value]);

  return (
    <Drawer opened={opened} onClose={onDrawerClosed} trapFocus={false}>
      <Drawer.Header
        withCloseButton
        leftSection={<EventValidationIcon bounded={bounded} valid={valid} size={24} h='100%' py='auto' />}
        data-testid={testIds.drawerHeader}
      >
        {title}
      </Drawer.Header>
      <Drawer.Body>
        <Center>
          <Picker
            className={classes.picker}
            height={260}
            wheelMode={isDesktop ? 'normal' : 'off'}
            value={pickerValue}
            onChange={onPickerChange}
            data-testid={testIds.picker}
          >
            <Picker.Column name='hours' data-testid={testIds.pickerHours}>
              {hoursOptions.map(renderOption)}
            </Picker.Column>
            <Picker.Column name='minutes' data-testid={testIds.pickerMinutes}>
              {minutesOptions.map(renderOption)}
            </Picker.Column>
          </Picker>
        </Center>
      </Drawer.Body>
      <Drawer.Footer h={80} />
    </Drawer>
  );
};
