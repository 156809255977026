import { Clock } from '@phosphor-icons/react';
import { exists, formatTime } from 'utils';
import { ReportValueType } from '@flow/flow-backend-types';
import { TimeEventProps } from '../../types';
import { EventBase, eventBaseTestIds } from '../../../EventBase';
import { useBoundError } from '../../hooks/useBoundError';
import { TimeEventDrawer, testIds as timeEventDrawerTestIds } from './TimeEventDrawer';

export const testIds = {
  getEventTestId: (id: string) => `time-event-${id}`,
  base: eventBaseTestIds,
  drawer: timeEventDrawerTestIds,
};

export function getTimeValue(value?: string | null) {
  return exists(value) ? formatTime(Number(value)) : '';
}

export const TimeEvent = ({
  reportKey,
  uiEvent,
  valid,
  bounded,
  bounds,
  lastReportedEvent,
  triggerReport,
  opened,
  onStartEditing,
  onFinishEditing,
  onOpenDrawer,
  onCloseDrawer,
}: TimeEventProps) => {
  const lastReportedValue = getTimeValue(lastReportedEvent?.reportedValue);
  const error = useBoundError(bounded, bounds, Number(lastReportedEvent?.reportedValue), 'TimeOfDayEvent', formatTime);

  const reportEvent = (newValue?: number | null) => {
    triggerReport({
      reportedValue: exists(newValue) ? String(newValue) : null,
      reportedValueType: ReportValueType.NUMBER,
    });
  };

  const finishedEdit = (finalValue?: number | null) => {
    reportEvent(finalValue);
    onFinishEditing(finalValue?.toString() ?? null);
  };

  return (
    <>
      <EventBase
        key={reportKey}
        child={uiEvent.isChild}
        title={uiEvent.title}
        mandatory={uiEvent.isMandatory}
        Icon={Clock}
        value={lastReportedValue}
        valid={valid}
        bounded={bounded}
        error={error}
        withVoice={uiEvent.tapToSpeakEnabled}
        onClick={onOpenDrawer}
        data-testid={testIds.getEventTestId(reportKey)}
      />
      <TimeEventDrawer
        opened={opened}
        title={uiEvent.title}
        value={lastReportedValue}
        valid={valid}
        bounded={bounded}
        onEditing={onStartEditing}
        onFinishedEditing={finishedEdit}
        onDrawerClosed={onCloseDrawer}
      />
    </>
  );
};
