import { GetFlowMetadataResponse } from '@flow/flow-backend-types';
import { hermes } from 'services/hermes';
import { config } from 'services/config';

export const inspectionMetaApi = {
  fetchInspectionMetaByFlowId: async (
    flowId: string,
    version: string,
  ): Promise<GetFlowMetadataResponse | undefined> => {
    const { data, status } = await hermes.get<GetFlowMetadataResponse>(
      `${config.getApiUrl()}/flows/${flowId}/${version}/flow-metadata`,
    );
    if (status !== 200) return undefined;
    return data;
  },
};
