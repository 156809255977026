import { Calendar } from '@phosphor-icons/react';
import { exists } from '@aiola/frontend';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useUserDateFormat } from 'stores/settings';
import { ReportValueType } from '@flow/flow-backend-types';
import { DateEventDrawer, testIds as dateDrawerTestIds } from './DateEventDrawer';
import { EventBase, eventBaseTestIds } from '../../../EventBase';
import { DateEventProps } from '../../types';
import { useBoundError } from '../../hooks/useBoundError';

export const testIds = {
  getEventTestId: (id: string) => `date-event-input-${id}`,
  base: eventBaseTestIds,
  drawer: dateDrawerTestIds,
};

export const DateEvent = ({
  reportKey,
  uiEvent,
  valid,
  bounded,
  bounds,
  lastReportedEvent,
  triggerReport,
  opened,
  onOpenDrawer,
  onCloseDrawer,
}: DateEventProps) => {
  const dateFormat = useUserDateFormat();
  const lastReportedValue = lastReportedEvent?.reportedValue;
  const reportedDate = useMemo(() => {
    if (lastReportedEvent && lastReportedValue) {
      const dateTime = Number(lastReportedValue) ?? null;
      const date = dayjs(dateTime);
      const isValid = date.isValid();
      return isValid ? date : undefined;
    }
    return undefined;
  }, [lastReportedValue]);
  const reportInMs = reportedDate?.valueOf();

  const error = useBoundError(bounded, bounds, reportInMs, 'DateEvent', (v) => dayjs(v).format(dateFormat));

  const reportEvent = (newValue?: number) => {
    triggerReport({
      reportedValue: exists(newValue) ? String(newValue) : null,
      reportedValueType: ReportValueType.NUMBER,
    });
  };

  return (
    <>
      <EventBase
        key={reportKey}
        child={uiEvent.isChild}
        title={uiEvent.title}
        mandatory={uiEvent.isMandatory}
        Icon={Calendar}
        value={reportedDate?.format(dateFormat)}
        valid={valid}
        bounded={bounded}
        error={error}
        withVoice={uiEvent.tapToSpeakEnabled}
        onClick={onOpenDrawer}
        data-testid={testIds.getEventTestId(reportKey)}
      />
      <DateEventDrawer
        opened={opened}
        title={uiEvent.title}
        value={reportInMs}
        valid={valid}
        bounded={bounded}
        onDrawerClosed={onCloseDrawer}
        onValuePicked={reportEvent}
      />
    </>
  );
};
