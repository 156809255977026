import { Button, Title, Text, Divider } from '@mantine/core';
import { Flow, ReportedMetadataField } from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/routes.config';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { toaster } from 'services/toaster';
import { useFlowStore, useSingleInstanceValidator, useOngoingExecutionsByFlow } from 'stores/flow';
import { useInspectionMetaStore } from 'stores/inspectionMeta';
import { Drawer, drawerTestIds, MetadataDrawer, metadataDrawerTestIds } from 'components';
import { useDisclosure } from '@mantine/hooks';
import { exists } from '@aiola/frontend';
import { OngoingExecutionList } from './OngoingExecutionList';

interface FlowDrawerProps {
  flow?: Flow;
  opened: boolean;
  onClose: () => void;
}

export const testIds = {
  drawer: drawerTestIds,
  title: 'flow-drawer-title',
  subtitle: 'flow-drawer-subtitle',
  listTitle: 'flow-drawer-list-title',
  startBtn: 'flow-drawer-start-button',
  metadata: metadataDrawerTestIds,
};

export const FlowDrawer = ({ flow, opened, onClose }: FlowDrawerProps) => {
  const { spyRef, spyClick } = useSpy();
  const { t } = useTranslation();
  const navigate = useForceNavigate();
  const isSingleInstanceValid = useSingleInstanceValidator();
  const { loadInspectionMeta } = useInspectionMetaStore(['loadInspectionMeta']);
  const { startExecution } = useFlowStore(['startExecution']);
  const executions = useOngoingExecutionsByFlow(flow?.id);
  const [metadataOpened, { open: openMetadata, close: closeMetadata }] = useDisclosure();

  const toastSingleInstanceError = (name: string) => {
    toaster.error({
      autoClose: false,
      message: t('flows.errors.singleInstance.message', { name }),
    });
  };

  const toastRequestError = () => toaster.error({ message: t('flows.errors.failedRequest.message') });

  const createExecution = async (flowId: string, version: string, metadata: ReportedMetadataField[] = []) => {
    const execution = await startExecution({
      flowId,
      version,
      preInspectionMetadata: metadata,
      uniqueIdentifier: '',
    });
    if (execution?.id) {
      navigate(ROUTES.INSPECTION(execution.id));
    } else toastRequestError();
  };

  const onSubmit = (metadata: ReportedMetadataField[]) => createExecution(flow!.id, flow!.activeVersion, metadata);

  const handleStartClick = async () => {
    if (!flow) return;
    spyClick(names.FormDrawer.StartNew, flow.id);

    if (!isSingleInstanceValid(flow.id)) {
      toastSingleInstanceError(flow.name);
      return;
    }

    const inspectionMeta = await loadInspectionMeta(flow.id, flow.activeVersion);
    if (!exists(inspectionMeta))
      toaster.error({
        message: t('home.errors.missingMetadata.title'),
      });
    else {
      const requiresMetadata = Boolean(inspectionMeta.preInspectionFields?.length);
      if (requiresMetadata) openMetadata();
      else createExecution(flow.id, flow.activeVersion);
    }
  };

  useSpyOpened(spyRef, names.FormDrawer.self, opened);

  return (
    <Drawer opened={opened} height='90vh' onClose={onClose}>
      <Drawer.Header withCloseButton />
      <Drawer.Body>
        <Drawer.Body.Sticky>
          <Title bg='white' order={5} ta='left' data-testid={testIds.title}>
            {flow?.name}
          </Title>
          <Text bg='white' c='cool.5' size='sm' data-testid={testIds.subtitle}>
            {flow?.description}
          </Text>
          {!!executions.length && (
            <Text bg='white' c='cool.5' pt={24} data-testid={testIds.listTitle}>
              {t('home.executionsDrawer.listTitle')}
            </Text>
          )}
        </Drawer.Body.Sticky>
        {flow && <OngoingExecutionList executions={executions} />}
      </Drawer.Body>
      <Divider />
      <Drawer.Footer>
        <Button onClick={handleStartClick} data-testid={testIds.startBtn}>
          {t('home.executionsDrawer.startBtn')}
        </Button>
        {flow && (
          <MetadataDrawer flowId={flow!.id} opened={metadataOpened} onClose={closeMetadata} onSubmit={onSubmit} />
        )}
      </Drawer.Footer>
    </Drawer>
  );
};
