import { Affix, CloseButton, Flex, Paper, Text } from '@mantine/core';
import { ArrowSquareOut, PlayCircle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { config } from 'services/config';
import { useTutorialNotification } from 'stores/settings';
import { names, useSpy, useSpyOpened } from 'services/espionage';

export const testIds = {
  notification: 'tutorial-notification',
  dismiss: 'tutorial-notification-dismiss',
};

export const TutorialNotification = () => {
  const { t } = useTranslation();
  const { isDismissed, onDismiss } = useTutorialNotification();
  const { spyClick, spyRef } = useSpy();

  const onClickDismiss = () => {
    spyClick(names.HomePage.DismissTutorial);
    onDismiss();
  };

  const onClick = () => spyClick(names.HomePage.Tutorial);

  useSpyOpened(spyRef, names.HomePage.Tutorial, !isDismissed);

  if (isDismissed) return null;
  return (
    <Affix w='100%' position={{ bottom: 0 }} data-testid={testIds.notification}>
      <Paper bg='amber.2' c='amber.7' pt='sm' pb='lg' px='xs'>
        <Flex justify='space-between' align='center'>
          <Flex align='center' gap='xs'>
            <PlayCircle weight='fill' />
            <Text component='a' className='underline' href={config.tutorialUrl} target='_blank' onClick={onClick}>
              {t('appMenu.tutorial')}
            </Text>
            <ArrowSquareOut />
          </Flex>
          <CloseButton c='amber.7' onClick={onClickDismiss} data-testid={testIds.dismiss} />
        </Flex>
      </Paper>
    </Affix>
  );
};
