import { useCallback, useMemo } from 'react';
import { useAppStore } from './app.store';
import { AnyDistraction } from './app.types';

export function useDistraction(id: AnyDistraction) {
  const { distractions, addDistraction, removeDistraction } = useAppStore([
    'distractions',
    'addDistraction',
    'removeDistraction',
  ]);
  const distracting = distractions.has(id);
  const distract = useCallback(() => addDistraction(id), [id]);
  const concentrate = useCallback(() => removeDistraction(id), [id]);
  return { distracting, distract, concentrate };
}

export function useDistracted(ids: AnyDistraction[]) {
  const { distractions } = useAppStore(['distractions']);
  return useMemo(() => ids.some((id) => distractions.has(id)), [ids, distractions]);
}

export function useDebug() {
  return useAppStore((state) => state.debug);
}
