import { Stack, Flex, Text } from '@mantine/core';
import { ExecutionId } from '@flow/flow-backend-types';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { names, useSpy, SelectForm } from 'services/espionage';
import { toaster } from 'services/toaster';
import { ROUTES } from 'routes/routes.config';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { BaseCardLayout } from 'pages/HomePage/components';
import { EmptyState } from 'components';
import { useCurrentUser } from 'stores/auth';
import {
  useFlowStore,
  getMetadataExposedFields,
  isUserJoinedToExecution,
  useParticipantLimitValidator,
  Execution,
} from 'stores/flow';
import { useUserDateFormat } from 'stores/settings';
import classes from './FlowDrawer.module.css';

interface OngoingExecutionListProps {
  executions: Execution[];
}

export const testIds = {
  container: 'ongoing-execution-list-container',
  emptyState: 'ongoing-execution-empty',
  getExecution: (id: ExecutionId) => `ongoing-execution-${id}`,
};

export const OngoingExecutionList = ({ executions }: OngoingExecutionListProps) => {
  const { spyClick } = useSpy();
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const isParticipantLimitReached = useParticipantLimitValidator();

  const dateFormat = useUserDateFormat();
  const navigate = useForceNavigate();
  const { joinExecution } = useFlowStore(['joinExecution']);

  const toastParticipantLimitError = () => toaster.error({ message: t('flows.errors.participantLimit.message') });
  const toastRequestError = () => toaster.error({ message: t('flows.errors.failedRequest.message') });

  const currentUserId = currentUser?.userId ?? '';

  const joinedExecutions = useMemo(
    () =>
      executions.map((execution) => ({ ...execution, isJoined: isUserJoinedToExecution(execution, currentUserId) })),
    [executions, currentUserId],
  );

  const sortedExecutions = useMemo(() => {
    const joined = joinedExecutions.filter(({ isJoined }) => isJoined);
    const notJoined = joinedExecutions.filter(({ isJoined }) => !isJoined);
    return [...joined, ...notJoined];
  }, [joinedExecutions]);

  const resume = (id: ExecutionId) => navigate(ROUTES.INSPECTION(id));

  const join = async (execution: Execution) => {
    if (isParticipantLimitReached(execution.flowRef.id, execution.joinedUsers.length)) {
      toastParticipantLimitError();
      return;
    }
    const hasJoined = await joinExecution(execution.id);
    if (hasJoined) navigate(ROUTES.INSPECTION(execution.id));
    else toastRequestError();
  };

  const handleExecutionClick = async (execution: Execution & { isJoined: boolean }) => {
    spyClick(names.FormDrawer.Select, {
      flowId: execution.flowRef.id,
      executionId: execution.id,
      actionType: execution.isJoined ? SelectForm.RESUME : SelectForm.JOIN,
    });

    if (execution.isJoined) resume(execution.id);
    else await join(execution);
  };

  if (!executions.length)
    return (
      <Flex align='center' justify='center' className={classes.emptyStateWrapper}>
        <EmptyState hidden={false} data-testid={testIds.emptyState}>
          <Text c='cool.4' fw={600} maw={250} ta='center'>
            {t('home.emptyState.formDraweText')}
          </Text>
        </EmptyState>
      </Flex>
    );

  return (
    <Stack gap='tn' data-testid={testIds.container}>
      {sortedExecutions.map((execution) => (
        <BaseCardLayout
          withBorder
          mih={76}
          key={execution.id}
          id={execution.id}
          mb='tn'
          className='z-0'
          subtitle={
            getMetadataExposedFields(execution)[0] && (
              <Text size='sm' c='cool.7' mb='tn'>
                {getMetadataExposedFields(execution)[0]}
              </Text>
            )
          }
          participants={execution.joinedUsers}
          metadata={dayjs(execution.createdAt).format(`HH:mm • ${dateFormat}`)}
          actionButtonTitle={execution.isJoined ? t('common.resume') : t('common.join')}
          onClick={() => handleExecutionClick(execution)}
          data-testid={testIds.getExecution(execution.id)}
        />
      ))}
    </Stack>
  );
};
