import { ExecutionId, FailedAction, PendingAction, UserId } from '@flow/flow-backend-types';

export enum DeviceOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export enum AppLayout {
  Custom = 'custom view',
  Regular = 'regular view',
}

export interface FailedActionWithReason {
  action: PendingAction;
  reason: FailedAction;
}

export interface SupportTicketRequest {
  userId?: UserId;
  executionId?: ExecutionId;
  name: string;
  type: string;
  description: string;
  media: File[];
}

export type CommonDistraction =
  | 'app-menu'
  | 'date-format-menu'
  | 'language-menu'
  | 'support-form'
  | 'edit-event'
  | 'image-edit'
  | 'image-capture'
  | 'review';
export type AnyDistraction = CommonDistraction | (string & {});
