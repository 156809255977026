import { Flow, UserIdentity, UserId } from '@flow/flow-backend-types';
import dayjs from 'dayjs';
import { generateId } from '@aiola/frontend';
import { exists, finishedExecutionStatuses } from 'utils';
import { Execution, ExecutionStatus } from './flow.types';

export const createExecution = (
  {
    flowRef,
    preInspectionMetadata,
    uniqueIdentifier,
  }: Pick<Execution, 'flowRef' | 'preInspectionMetadata' | 'uniqueIdentifier'>,
  userIdentity: UserIdentity,
): Execution => ({
  id: generateId(),
  flowRef,
  preInspectionMetadata,
  uniqueIdentifier,
  status: 'inProgress',
  createdBy: userIdentity,
  joinedUsers: [userIdentity],
  workedOn: [userIdentity],
  createdAt: Date.now(),
  originalTZName: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export const getTimeAgo = (time: string) => Date.now() - parseInt(time, 10);

export const sortByExecutionCreationDate = (executions: Execution[]) =>
  executions.sort((a, b) => b.createdAt - a.createdAt);

export const sortByExecutionFinishDate = (executions: Execution[]) =>
  executions.sort((a, b) => {
    if (a.finishedAt && b.finishedAt) return b.finishedAt - a.finishedAt;
    if (a.finishedAt) return -1;
    if (b.finishedAt) return 1;
    return 0;
  });

export const getIdsAndVersions = (flows: Flow[], executions: Execution[]) => {
  const flowIdsAndVersions = flows.map(({ id, activeVersion }) => `${id}:${activeVersion}`);
  const executionIdsAndVersions = executions.map(({ id, flowRef }) => `${flowRef.id}:${flowRef.version}:${id}`);
  return [...new Set([...flowIdsAndVersions, ...executionIdsAndVersions])];
};

export function filterExecutionsByStatuses(executions: Execution[], statuses: ExecutionStatus[]) {
  return executions.filter((execution) => statuses.includes(execution.status));
}

export function filterExecutionsByUser(executions: Execution[], userId?: string) {
  return executions.filter(({ createdBy, workedOn }) => {
    const isCreatedByCurrentUser = createdBy.userId === userId;
    const isJoinedBefore = workedOn.some((user) => user.userId === userId);
    return isCreatedByCurrentUser || isJoinedBefore;
  });
}

export function filterOngoingExecutionsByUser(executions: Execution[], userId?: string) {
  return executions.filter(({ createdBy, workedOn, status }) => {
    const isCompleted = finishedExecutionStatuses.includes(status);
    const isCreatedByCurrentUser = createdBy.userId === userId;
    const isJoinedBefore = workedOn.some((user) => user.userId === userId);
    return (isCreatedByCurrentUser || isJoinedBefore) && !isCompleted;
  });
}

export const filterJoinableExecutions = (executions: Execution[], flows: Flow[], userId: string): Execution[] => {
  const flowMap = new Map(flows.map((flow) => [flow.id, flow.maxInspectors]));

  return executions.filter(({ flowRef, joinedUsers, status }) => {
    if (status !== 'inProgress') return false;

    const maxInspectors = flowMap.get(flowRef.id);

    if (maxInspectors === undefined) return false;
    if (maxInspectors === 0) return true;

    return joinedUsers.length < maxInspectors || joinedUsers.some((user) => user.userId === userId);
  });
};

export const filterCompletedExecutions = (executions: Execution[]): Execution[] =>
  executions.filter(({ status }) => finishedExecutionStatuses.includes(status));

export const filterExecutionsByFinishedDate = (executions: Execution[], filterDates: string[]) => {
  if (!filterDates.length) return executions;
  const now = dayjs();

  const filterByFinishedDate = (finishedAt?: number) => {
    if (!finishedAt) return false;
    return filterDates.some((filter) => {
      const finishedAtDate = dayjs(finishedAt);

      switch (filter) {
        case 'today':
          return finishedAtDate.isSame(now, 'day');
        case 'yesterday':
          return finishedAtDate.isSame(now.subtract(1, 'day'), 'day');
        case 'thisWeek':
          return finishedAtDate.isBetween(now.startOf('isoWeek'), now.endOf('isoWeek'), 'day', '[]');
        case 'last7Days':
          return finishedAtDate.isBetween(now.subtract(7, 'day'), now, 'day', '[]');
        default:
          return true;
      }
    });
  };

  return executions.filter(({ finishedAt }) => filterByFinishedDate(finishedAt));
};

export const isUserJoinedToExecution = (execution: Execution, userId: UserId): boolean =>
  execution.joinedUsers.some((user: UserIdentity) => user.userId === userId);

export const generateUniqueIdentifier = (user: UserIdentity, dateFormat: string) => {
  const formattedDate = dayjs().format(`hh:mm:ssA, ${dateFormat}`);
  return `${user.givenName} ${user.familyName} (${formattedDate})`;
};

export const joinUserToExecution = (execution: Execution, user: UserIdentity): Execution => ({
  ...execution,
  workedOn: execution.workedOn.includes(user) ? execution.workedOn : [...execution.workedOn, user],
  joinedUsers: [...execution.joinedUsers, user],
});

export const markExecutionAsReviewed = (execution: Execution, user: UserIdentity): Execution => ({
  ...execution,
  status: 'inReview',
  reviewedBy: user,
  reviewedAt: Date.now(),
});

export const markExecutionAsInProgress = (execution: Execution): Execution => ({
  ...execution,
  reviewedBy: undefined,
  reviewedAt: undefined,
  status: 'inProgress',
});

export const markExecutionAsFinished = (execution: Execution, user: UserIdentity): Execution => ({
  ...execution,
  status: 'pending',
  postInspectionMetadata: [],
  finishedBy: user,
  finishedAt: Date.now(),
});

export const markExecutionAsCanceled = (execution: Execution, user: UserIdentity): Execution => ({
  ...execution,
  status: 'cancelled',
  finishedBy: user,
  finishedAt: Date.now(),
});

export function getMetadataExposedFields(execution: Execution): string[] {
  if (!execution) return [];
  return execution.preInspectionMetadata?.map(({ value, exposed }) => (exposed ? value : null)).filter(exists) ?? [];
}
