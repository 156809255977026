import { AnyBridge, getContainerBridges, isUiEventStatic, useUiEventStore } from 'stores/uiEvent';
import { useContainerById, useContainerStore } from 'stores/container';
import { useMemo } from 'react';
import { exists } from '@aiola/frontend';
import { BridgedUiEvent } from '../EventItem/EventItem.types';

/** Get all the functional events for the given containers, sorted by order with child events in between.
 * @example [parent1, child1_1, child1_2, parent2, child2_1, child2_2] */
export function useBridgedUiEvents(containerId: string): BridgedUiEvent[][] {
  const { containerEventsMap, containerTemplatesMap } = useContainerStore([
    'containerEventsMap',
    'containerTemplatesMap',
  ]);
  const container = useContainerById(containerId);
  const { uiEvents } = useUiEventStore(['uiEvents']);

  const getParentBridges = (bridges: AnyBridge[]) =>
    bridges.filter((b) => !b.isChild && !isUiEventStatic(uiEvents[b.uiEventId])).sort((a, b) => a.order - b.order);

  const getChildBridgeMap = (bridges: AnyBridge[]) =>
    new Map(bridges.filter((b) => b.isChild).map((b) => [b.uiEventId, b]));

  const getParentChildBridges = (childMap: Map<string, AnyBridge>, parent: AnyBridge) =>
    parent.childrenIds
      .map((id) => childMap.get(id))
      .filter(exists)
      .sort((a, b) => a.order - b.order);

  const bridgeEvent = (bridge: AnyBridge) =>
    ({
      ...bridge,
      ...uiEvents[bridge.uiEventId],
    }) as BridgedUiEvent;

  const buildBridgeArray = (parents: AnyBridge[], childMap: Map<string, AnyBridge>) =>
    parents.map((parent) => {
      const parentChildren = getParentChildBridges(childMap, parent);
      return [parent, ...parentChildren].map(bridgeEvent);
    });

  return useMemo(() => {
    const containerBridges = getContainerBridges(container, containerEventsMap, containerTemplatesMap);
    const parents = getParentBridges(containerBridges);
    const childrenMap = getChildBridgeMap(containerBridges);
    return buildBridgeArray(parents, childrenMap);
  }, [containerId, containerEventsMap, containerTemplatesMap, container, uiEvents]);
}
