import { Flex, MantineColor, Text, ThemeIcon, Title, Image, Box, ActionIcon } from '@mantine/core';
import { IconProps as TablerProps } from '@tabler/icons-react';
import { IconProps as PhosphorProps } from '@phosphor-icons/react';
import { FC, ReactNode, useEffect } from 'react';

interface DefaultModalContentProps {
  Icon?: FC<TablerProps> | FC<PhosphorProps>;
  imgSrc?: string;
  color?: MantineColor;
  title: ReactNode;
  message?: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
}

export const testIds = {
  title: 'default-modal-content-title',
  message: 'default-modal-content-message',
  icon: 'default-modal-content-icon',
  image: 'default-modal-image',
  imageIcon: 'default-modal-image-icon',
};

export const DefaultModalContent = ({
  Icon,
  imgSrc,
  color,
  title,
  message,
  onOpen,
  onClose,
}: DefaultModalContentProps) => {
  useEffect(() => {
    onOpen?.();
    return () => {
      onClose?.();
    };
  }, []);

  return (
    <Flex direction='column' align='center' gap='tiny' mb={40}>
      {imgSrc ? (
        <Box pos='relative'>
          <Image radius='md' mb='sm' w={200} src={imgSrc} alt='Modal image' data-testid={testIds.image} />
          {Icon && (
            <ActionIcon
              pos='absolute'
              variant='filled'
              color='white'
              bg='red.5'
              radius='xl'
              size='lg'
              right={-12}
              bottom={0}
              data-testid={testIds.imageIcon}
            >
              <Icon size={22} />
            </ActionIcon>
          )}
        </Box>
      ) : (
        Icon && (
          <ThemeIcon variant='transparent' size={84} color={color} mb='sm' data-testid={testIds.icon}>
            <Icon size={84} weight='duotone' />
          </ThemeIcon>
        )
      )}
      <Title fw={600} size={24} mb={10} c='cool.8' pos='relative' ta='center' data-testid={testIds.title}>
        {title}
      </Title>
      {message && (
        <Text ta='center' size='md' c='cool.6' className='whitespace-pre-line' data-testid={testIds.message}>
          {message}
        </Text>
      )}
    </Flex>
  );
};
