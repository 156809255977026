import { ActionIcon, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Camera } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { usePhotoContext } from 'contexts/ItemPhotoContext';
import { compressImage } from 'utils';
import { ImageUploadDrawer, testIds as drawerTestIds } from './ImageUploadDrawer/ImageUploadDrawer';

interface ImageCapturerProps {
  loading?: boolean;
  disabled?: boolean;
  onCapture?: (images: string[]) => void;
  onClose?: () => void;
  onClick?: () => void;
}

export const testIds = {
  drawer: drawerTestIds,
  trigger: 'image-capturer-trigger',
  wrapperTrigger: 'image-capturer-wrapper-trigger',
};

const MAX_FILE_UPLOAD_AMOUNT = 20;

export const ImageCapturer = ({ disabled, loading, onCapture, onClose, onClick }: ImageCapturerProps) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false, { onClose, onOpen: onClick });
  const { bulkUploadCounter, bulkUploadTotal } = usePhotoContext();

  const readFile = (file: File): Promise<string> => {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
  };

  const onChange = async (files?: File[]) => {
    if (!files) return;
    const images = await Promise.all(
      files.map(async (file) => {
        const compressedFile = await compressImage(file);
        return readFile(compressedFile);
      }),
    );
    onCapture?.(images);
  };

  const onClickTrigger = () => {
    if (disabled) return;
    open();
  };

  return (
    <>
      <Flex align='center' ml={-10} onClick={onClickTrigger} data-testid={testIds.wrapperTrigger}>
        <ActionIcon
          mr={1}
          size='xl'
          variant='transparent'
          disabled={disabled}
          loading={loading}
          color='gray.6'
          data-testid={testIds.trigger}
        >
          <Camera size={24} strokeWidth={2} weight='duotone' />
        </ActionIcon>
        <Text size='md' fw={600} c={disabled ? 'gray.3' : 'gray.7'}>
          {t('flows.images.takePhoto')} {!!bulkUploadTotal && `${bulkUploadCounter}/${bulkUploadTotal}`}
        </Text>
      </Flex>
      <ImageUploadDrawer opened={opened} maxFiles={MAX_FILE_UPLOAD_AMOUNT} onClose={close} onUpload={onChange} />
    </>
  );
};
