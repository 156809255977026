import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { useSessionStorage } from '@mantine/hooks';
import { noop } from 'utils';

export enum AnimationStage {
  loader,
  background,
  title,
  content,
  done,
}

export const CONTENT_TRANSITION_DURATION = 1000;
export const HOME_PAGE_FIRST_VISIT_SS_KEY = 'is-first-home-visit';

interface HomePageLoadState {
  animationStage: AnimationStage;
  onAnimationEnd: (stage: AnimationStage) => void;
}

const HomePageLoadContext = createContext<HomePageLoadState>({
  animationStage: AnimationStage.loader,
  onAnimationEnd: noop,
});

export const HomePageLoadProvider = ({ children }: PropsWithChildren) => {
  const [isFirstVisit, setIsFirstVisit] = useSessionStorage({
    key: HOME_PAGE_FIRST_VISIT_SS_KEY,
    defaultValue: true,
    getInitialValueInEffect: false,
  });
  const [animationStage, setAnimationStage] = useState(isFirstVisit ? AnimationStage.loader : AnimationStage.done);
  const onAnimationEnd = useCallback((stage: AnimationStage) => {
    setAnimationStage((prevStage) => {
      if (stage === prevStage) {
        const nextStage = stage + 1;
        if (nextStage === AnimationStage.done) setIsFirstVisit(false);
        return nextStage;
      }
      return prevStage;
    });
  }, []);

  const value = useMemo(() => ({ animationStage, onAnimationEnd }), [animationStage]);

  return <HomePageLoadContext.Provider value={value}>{children}</HomePageLoadContext.Provider>;
};

export function useHomePageLoad(stage: AnimationStage) {
  const { onAnimationEnd, ...rest } = useContext(HomePageLoadContext);
  const finishAnimationStage = useCallback(() => onAnimationEnd(stage), [stage, onAnimationEnd]);
  return { ...rest, finishAnimationStage };
}
