import { Carousel } from '@mantine/carousel';
import { Stack, Title, Text } from '@mantine/core';
import { ExecutionId } from '@flow/flow-backend-types';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { ROUTES } from 'routes/routes.config';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { useMyOngoingExecutions, getMetadataExposedFields } from 'stores/flow';
import { useUserDateFormat } from 'stores/settings';
import { BaseCardLayout } from 'pages/HomePage/components';
import classes from './OngoingFormCarousel.module.css';

export const testIds = {
  container: 'ongoing-carousel-container',
  title: 'ongoing-carousel-title',
  getOngoingItem: (id: ExecutionId) => `ongoing-item-${id}`,
};

export const OngoingFormCarousel = () => {
  const { spyClick, spySwipe } = useSpy();
  const { t } = useTranslation();
  const executions = useMyOngoingExecutions();
  const dateFormat = useUserDateFormat();
  const navigate = useForceNavigate();
  const [activeSlide, setActiveSlide] = useState(0);

  const handleClick = (executionId: ExecutionId, flowName: string) => () => {
    spyClick(names.HomePage.SelectOngoingForm, { executionId, flowName });
    navigate(ROUTES.INSPECTION(executionId));
  };

  const handleSwipe = (index: number) => {
    spySwipe(names.HomePage.SwipeOngoingForm, { ongoingFromsLength: executions.length, ongoingFromIndex: index });
    setActiveSlide(index);
  };

  if (!executions.length) return null;

  return (
    <Stack gap='tn' mt='xl' data-testid={testIds.container}>
      <Title order={6} pl='sm' c='cool.5' data-testid={testIds.title}>
        {t('home.ongoingForms.title')}
      </Title>
      <Carousel
        slideSize={executions.length > 1 ? '90%' : '100%'}
        mr={executions.length > 1 ? 0 : 'sm'}
        align='start'
        withControls={false}
        withIndicators={executions.length > 1}
        classNames={classes}
        onSlideChange={handleSwipe}
      >
        {executions.map((execution, index) => (
          <Carousel.Slide
            pl='sm'
            key={execution.id}
            data-active-slide={index === activeSlide}
            data-execution-id={execution.id}
          >
            <BaseCardLayout
              h={150}
              id={execution.id}
              variant='thematic'
              title={
                <Title order={5} lh='1.2' lineClamp={2}>
                  {execution.title}
                </Title>
              }
              subtitle={<Text size='sm'>{getMetadataExposedFields(execution)[0]}</Text>}
              participants={execution.joinedUsers}
              metadata={dayjs(execution.createdAt).format(`HH:mm • ${dateFormat}`)}
              actionButtonTitle={t('common.resume')}
              onClick={handleClick(execution.id, execution.title)}
              data-testid={testIds.getOngoingItem(execution.id)}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Stack>
  );
};
