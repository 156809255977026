import { GenerationMethod } from '@flow/flow-backend-types';
import { exists } from 'utils';
import { useCurrentUser } from 'stores/auth';
import { useCEBridges, useContainerStaticEvents, useUIEventById } from 'stores/uiEvent';
import { useReportStore } from './report.store';
import { getReportCollectionKey, pullLastReport, getLastReportedValues } from './report.utils';
import { ContainerProgressionStatus, ReportDynamicData } from './report.types';

export function useReporter({
  executionId,
  containerId,
  eventId,
}: {
  executionId: string;
  containerId: string;
  eventId: string;
}) {
  const user = useCurrentUser();
  return useReportStore((state) => ({
    lastReport: pullLastReport(state.reports, containerId, eventId),
    triggerReport: (args: Partial<ReportDynamicData>, valid?: boolean) => {
      if (user?.userId) {
        const reportSlice: ReportDynamicData = {
          ...args,
          userId: user?.userId,
          containerId,
          eventDefId: eventId,
          flowExecutionId: executionId,
          generationMethod: GenerationMethod.USER_ACTION,
        };
        state.send([reportSlice]);
      }
      if (exists(valid)) {
        // TODO send validation reports
      }
    },
  }));
}

export function useContainerReports(containerId: string) {
  const ceBridges = useCEBridges(containerId);
  const reportedEvents = useReportStore((state) => state.reports);
  const { imageEventId = '', applicabilityEventId = '' } = useContainerStaticEvents(containerId);

  const imageReports = reportedEvents[getReportCollectionKey(containerId, imageEventId)] ?? [];

  const applicabilityReports = reportedEvents[getReportCollectionKey(containerId, applicabilityEventId)] ?? [];

  const eventsReports = ceBridges.flatMap(
    ({ uiEventId }) => reportedEvents[getReportCollectionKey(containerId, uiEventId)] ?? [],
  );

  return [...imageReports, ...applicabilityReports, ...eventsReports];
}

export function useLastReportedValue(containerId: string, eventId: string) {
  const { reports } = useReportStore(['reports']);
  const uiEvent = useUIEventById(eventId);

  if (!uiEvent) return undefined;
  const reportedValues = getLastReportedValues(containerId, uiEvent, reports);

  if (!reportedValues.length) return undefined;
  return uiEvent.type === 'MultiSelectEvent' ? reportedValues : reportedValues[0];
}

export function useContainerProgression(containerId: string) {
  return useReportStore((state) => state.progression[containerId]) ?? ContainerProgressionStatus.NOT_STARTED;
}
