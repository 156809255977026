import { ComponentProps, PropsWithChildren } from 'react';
import { Flex, MantineProvider, px } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { IconContext } from '@phosphor-icons/react';
import { OfflineBanner } from 'components';
import { SVGDefs } from 'assets';
import { ZIndex } from 'consts';

interface PageProviderProps extends PropsWithChildren {
  theme: ComponentProps<typeof MantineProvider>['theme'];
}

const iconDefaultValues = {
  color: 'currentColor',
  size: px(24),
};

export const PageProvider = ({ children, theme }: PageProviderProps) => (
  <MantineProvider theme={theme}>
    <ModalsProvider modalProps={{ zIndex: ZIndex.Modals }}>
      <IconContext.Provider value={iconDefaultValues}>
        <SVGDefs />
        <OfflineBanner />
        <Flex w='100%' direction='column'>
          {children}
        </Flex>
        <Notifications position='top-center' zIndex={ZIndex.Notifications} />
      </IconContext.Provider>
    </ModalsProvider>
  </MantineProvider>
);
