import { MantineColor, Drawer as MantineDrawer, ModalBaseProps, Stack } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { DrawerHeader, testIds as headerTestIds } from './DrawerHeader';
import { DrawerBody, testIds as bodyTestIds } from './DrawerBody';
import { DrawerFooter, testIds as footerTestIds } from './DrawerFooter';
import { DrawerProvider } from './DrawerProvider';

interface DrawerProps extends PropsWithChildren {
  height?: number | string;
  opened: boolean;
  transitionProps?: ModalBaseProps['transitionProps'];
  withOverlay?: boolean;
  trapFocus?: boolean;
  zIndex?: number;
  variant?: 'default' | 'grayed';
  onClose: () => void;
}

export const testIds = {
  content: 'drawer-content',
  overlay: 'drawer-overlay',
  header: headerTestIds,
  body: bodyTestIds,
  footer: footerTestIds,
};

const bgVariants: Record<NonNullable<DrawerProps['variant']>, MantineColor> = {
  default: 'white',
  grayed: 'gray.1',
};

/**
 * @example
 * <Drawer opened={opened} onClose={onClose} h='50vh'>
 *  <Drawer.Header withCloseButton>Header</Drawer.Header>
 *  <Drawer.Body>
 *    <Drawer.Body.Sticky>Sticky content</Drawer.Body.Sticky>
 *    Body content
 *  </Drawer.Body>
 *  <Drawer.Footer>Footer</Drawer.Footer>
 * </Drawer>
 */
export const Drawer = ({
  children,
  height,
  variant = 'default',
  withOverlay = true,
  onClose,
  ...props
}: DrawerProps) => (
  <DrawerProvider onClose={onClose}>
    <MantineDrawer.Root size={height} position='bottom' radius='24px 24px 0 0' onClose={onClose} {...props}>
      {withOverlay && <MantineDrawer.Overlay data-testid={testIds.overlay} />}
      <MantineDrawer.Content bg={bgVariants[variant]} data-testid={testIds.content}>
        <Stack gap='zero' justify='space-between' h='100%'>
          {children}
        </Stack>
      </MantineDrawer.Content>
    </MantineDrawer.Root>
  </DrawerProvider>
);

Drawer.Header = DrawerHeader;
Drawer.Body = DrawerBody;
Drawer.Footer = DrawerFooter;
