import { ContainerId, GeneratedSource } from '@flow/flow-backend-types';
import { useScrollIntoView, useTimeout } from '@mantine/hooks';
import { CONTAINER_SCROLL_DELAY } from 'consts';
import { useCallback, useEffect, useMemo } from 'react';
import { useContainerById, useContainerStore } from 'stores/container';
import { useFocusData, useFocusStore } from 'stores/focus';
import { getContainerBridges } from 'stores/uiEvent';

export function useContainerDisclosure(containerId: ContainerId) {
  const container = useContainerById(containerId);
  const { openParentContainersMap, toggleParentContainerOpen } = useContainerStore([
    'containers',
    'openParentContainersMap',
    'toggleParentContainerOpen',
  ]);
  const { focusContainer, blurContainer } = useFocusStore(['focusContainer', 'blurContainer']);
  const { focusedContainerId, generatedSource } = useFocusData();
  const isParent = Number(container?.childrenIds?.length) > 0;

  const opened = useMemo(
    () => (isParent ? openParentContainersMap.has(containerId) : focusedContainerId === containerId),
    [openParentContainersMap, focusedContainerId, isParent, containerId],
  );

  const toggle = useCallback(() => {
    if (isParent) toggleParentContainerOpen(containerId);
    else if (opened) blurContainer();
    else focusContainer(containerId);
  }, [opened, isParent, containerId]);

  const source = useMemo(
    () => (focusedContainerId === containerId ? generatedSource : undefined),
    [focusedContainerId, containerId, generatedSource],
  );

  return { opened, toggle, source };
}

export function useGenerationCount(containerId: ContainerId) {
  const { containers } = useContainerStore(['containers']);

  const countGenerations = (id: ContainerId): number => {
    const container = containers[id];
    if (!container || !container.childrenIds || container.childrenIds.length === 0) return 0;
    return 1 + Math.max(...container.childrenIds.map(countGenerations));
  };

  return useMemo(() => countGenerations(containerId), [containerId]);
}

export function useChildContainers(containerId?: ContainerId) {
  const { containers, rootContainerIds } = useContainerStore(['containers', 'rootContainerIds']);
  const container = useContainerById(containerId);

  return useMemo(() => {
    // TODO: should consider filters
    if (!containerId) return rootContainerIds.map((id) => containers[id]);
    if (!container) return [];
    return container.childrenIds.map((id) => containers[id]);
  }, [containers, container, rootContainerIds]);
}

/** Count the number of child containers and events in this container */
export function useMemberCount(containerId: ContainerId) {
  const { containerEventsMap, containerTemplatesMap } = useContainerStore([
    'containerEventsMap',
    'containerTemplatesMap',
  ]);
  const container = useContainerById(containerId);

  return useMemo(() => {
    if (!container) return 0;
    const bridges = getContainerBridges(container, containerEventsMap, containerTemplatesMap);
    return bridges.length + (container.childrenIds?.length ?? 0);
  }, [container, containerEventsMap, containerTemplatesMap]);
}

export function useContainerAutoScroll(opened: boolean, source?: GeneratedSource) {
  const { scrollTop } = useContainerStore(['scrollTop']);
  const { targetRef, scrollIntoView } = useScrollIntoView<HTMLDivElement>({ offset: scrollTop + 4, duration: 300 });
  // wait for previous container to close, to prevent overscrolling
  const { start: delayedScroll, clear: clearDelayedScroll } = useTimeout(scrollIntoView, CONTAINER_SCROLL_DELAY);

  useEffect(() => {
    if (opened && targetRef.current && source === GeneratedSource.VOICE) delayedScroll();
    return clearDelayedScroll;
  }, [source, opened]);

  return targetRef;
}
