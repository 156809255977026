import { getRandomArbitrary } from '.';

interface Range {
  min: number;
  max: number;
}

export interface ThemeCircleRange {
  top: Range;
  left: Range;
}
/**
 * Generates an object of CSS variables like:
 * {
 *   '--circle1-top': '12%',
 *   '--circle1-left': '-50%',
 *   ...
 * }
 * based on the given array of (top/left) range coordinates.
 */
export function getThemeCircleStyles(circleRanges: ThemeCircleRange[]) {
  return circleRanges.reduce<Record<string, string>>((styles, range, index) => {
    const randomTop = getRandomArbitrary(range.top.min, range.top.max);
    const randomLeft = getRandomArbitrary(range.left.min, range.left.max);

    const circleNumber = index + 1;
    styles[`--circle${circleNumber}-top`] = `${randomTop}%`;
    styles[`--circle${circleNumber}-left`] = `${randomLeft}%`;

    return styles;
  }, {});
}
