import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ReportValueType } from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { generateId } from '@aiola/frontend';
import { getReportCollectionKey, ImageReportValue, useReporter, useReportStore } from 'stores/report';
import { createThumbnail, exists, getImageSize, noop } from 'utils';
import { ExecutionRouteParams } from 'routes/routes.config';
import { useContainerStaticEvents } from 'stores/uiEvent';
import { getImageUrl, imageApi } from 'services/api';
import { names, useSpy } from 'services/espionage';
import { modalManager } from 'services/modalManager';
import { toaster } from 'services/toaster';
import { Trash } from '@phosphor-icons/react';
import { ReportedImageData, resolveImageData } from './ItemPhotoContext.utils';

const ImageEditor = React.lazy(() =>
  // TODO: update import path after components will be moved to the new structure
  import('components/Image/ImageEditor').then((module) => ({ default: module.ImageEditor })),
);

interface ItemPhotoContextState {
  containerId?: string;
  imageData: ReportedImageData[];
  /** Is loading upload of newly captured image? */
  loadingUpload: boolean;
  /** Is loading new image that was received from a report? */
  loadingNewReport: boolean;
  bulkUploadCounter: number;
  bulkUploadTotal: number;
  uploadImage: (base64Image: string) => Promise<void>;
  uploadImages: (base64Images: string[]) => Promise<void>;
  deleteImage: (imageId: string) => void;
  openEditor: (imageId: string) => void;
}

const itemPhotoContextDefaultValues: ItemPhotoContextState = {
  containerId: undefined,
  imageData: [],
  loadingUpload: false,
  loadingNewReport: false,
  bulkUploadCounter: 0,
  bulkUploadTotal: 0,
  uploadImage: noop,
  uploadImages: noop,
  deleteImage: noop,
  openEditor: noop,
};

const ItemPhotoContext = createContext<ItemPhotoContextState>(itemPhotoContextDefaultValues);

interface ItemPhotoProviderProps extends PropsWithChildren {
  containerId: string;
}

export const ItemPhotoProvider = ({ containerId, children }: ItemPhotoProviderProps) => {
  const { t } = useTranslation();
  const { executionId } = useParams() as ExecutionRouteParams;
  const { spyMount, spyUnmount, spyClick } = useSpy();
  const { imageEventId } = useContainerStaticEvents(containerId);
  const reports = useReportStore((state) => state.reports[getReportCollectionKey(containerId, imageEventId!)] ?? []);
  const { triggerReport } = useReporter({ executionId, containerId, eventId: imageEventId! });
  const [imageData, setImageData] = useState<Map<string, ReportedImageData>>(new Map());
  const [loadingNewReport, setLoadingNewReport] = useState(false);
  const [loadingEditUpload, setLoadingEditUpload] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const reportCount = reports.length;
  const [bulkImagesTotal, setBulkImagesTotal] = useState(0);
  const bulkImageDataRef = useRef<Map<string, ReportedImageData>>(new Map());

  const resolveReportedImages = async () => {
    setLoadingNewReport(true);
    const newData = await resolveImageData(imageData, reports, executionId, containerId);
    setImageData(newData);
    setLoadingNewReport(false);
  };

  // resolve images in case of incoming report
  useEffect(() => {
    resolveReportedImages();
  }, [reportCount]);

  const reportImage = (imageId: string, action: ImageReportValue) => {
    const data = imageData.get(imageId)! ?? bulkImageDataRef.current.get(imageId);
    const extraDetails =
      action === ImageReportValue.DELETE
        ? { imageId }
        : { image: { id: imageId, original: data.originalId, edited: data.editedId, thumbnail: data.thumbnailId } };
    triggerReport({ reportedValue: action, reportedValueType: ReportValueType.STRING, extraDetails });
  };

  const addNewImageToData = async (imageId: string, originalId: string, base64Image: string, thumbnailId?: string) => {
    const [width, height] = await getImageSize(base64Image);
    const newImageData: ReportedImageData = {
      id: imageId,
      originalId,
      originalUrl: getImageUrl(originalId, executionId, containerId),
      originalBase64: base64Image,
      thumbnailId,
      width,
      height,
      error: false,
    };
    setImageData((data) => {
      data.set(imageId, newImageData);
      return new Map(data);
    });
    bulkImageDataRef.current.set(imageId, newImageData);
  };

  const onUploadError = () =>
    toaster.error({
      title: t('flows.images.uploadError.title'),
      message: t('flows.images.uploadError.message'),
    });

  const uploadImage = async (base64Image: string) => {
    const newOriginalId = await imageApi.createImage({
      executionId,
      containerId,
      base64Image,
      fileUsage: 'original_image',
    });
    if (newOriginalId) {
      const newImageId = generateId(4);
      const thumbnailId = await generateThumbnail(base64Image);
      await addNewImageToData(newImageId, newOriginalId, base64Image, thumbnailId);
      reportImage(newImageId, ImageReportValue.CREATE);
    } else onUploadError();
  };

  const uploadImages = async (base64Images: string[]) => {
    setLoadingUpload(true);
    setBulkImagesTotal(base64Images.length);
    for (const base64Image of base64Images) {
      await uploadImage(base64Image);
    }
    setBulkImagesTotal(0);
    bulkImageDataRef.current = new Map();
    setLoadingUpload(false);
  };

  const [editedImageId, setEditedImageId] = useState<string | null>(null);
  const imageDataBeingEdited = useMemo(() => imageData.get(editedImageId ?? ''), [imageData, editedImageId]);

  const openEditor = (imageId: string) => {
    setEditedImageId(imageId);
  };

  const closeEditor = () => {
    setEditedImageId(null);
  };

  const removeEditedImageFromData = (imageId: string) => {
    setImageData((data) => {
      const image = data.get(imageId)!;
      data.set(imageId, {
        ...image,
        editedId: undefined,
        editedUrl: undefined,
        editedBase64: undefined,
      });
      return new Map(data);
    });
  };

  const updateImageThumbnailData = (imageId: string, thumbnailId: string) => {
    setImageData((data) => {
      const image = data.get(imageId)!;
      data.set(imageId, {
        ...image,
        thumbnailId,
      });
      return new Map(data);
    });
  };

  const restoreOriginal = async () => {
    if (!imageDataBeingEdited) return;
    if (imageDataBeingEdited.editedId) {
      setLoadingEditUpload(true);
      const imageIds = [imageDataBeingEdited.editedId, imageDataBeingEdited.thumbnailId].filter(exists);
      const deleteSuccessful = await imageApi.deleteImage({
        executionId,
        containerId,
        imageIds,
      });
      if (deleteSuccessful) {
        const thumbnailId = await generateThumbnail(imageDataBeingEdited.originalBase64);
        removeEditedImageFromData(imageDataBeingEdited.id);
        if (thumbnailId) updateImageThumbnailData(imageDataBeingEdited.id, thumbnailId);
        reportImage(imageDataBeingEdited.id, ImageReportValue.EDIT);
      }
      setLoadingEditUpload(false);
    }
    setEditedImageId(null);
  };

  const placeEditedImageInData = (imageId: string, editedId: string, editedBase64: string, thumbnailId?: string) => {
    const editedUrl = getImageUrl(editedId, executionId, containerId);
    setImageData((data) => {
      const image = data.get(imageId)!;
      data.set(imageId, {
        ...image,
        editedId,
        editedUrl,
        editedBase64,
        thumbnailId,
      });
      return new Map(data);
    });
  };

  const generateThumbnail = async (base64String: string) => {
    const base64Thumbnail = await createThumbnail(base64String);
    if (!base64Thumbnail) return undefined;
    const thumbnailId = await imageApi.createImage({
      executionId,
      containerId,
      base64Image: base64Thumbnail,
      fileUsage: 'thumbnail_image',
    });
    return thumbnailId ?? undefined;
  };

  const onEdit = async (base64Image?: string) => {
    if (!imageDataBeingEdited || !base64Image) return;
    const { id, originalId, editedId, thumbnailId } = imageDataBeingEdited;
    setLoadingEditUpload(true);
    const newEditedImageId = await imageApi.updateImage({
      executionId,
      containerId,
      imageId: editedId,
      originalFileId: originalId,
      base64Image,
      fileUsage: 'edited_image',
    });

    if (newEditedImageId) {
      const newThumbnailId = await generateThumbnail(base64Image);
      if (thumbnailId) await imageApi.deleteImage({ executionId, containerId, imageIds: [thumbnailId] });
      placeEditedImageInData(id, newEditedImageId, base64Image, newThumbnailId);
      reportImage(id, ImageReportValue.EDIT);
    } else onUploadError();
    setLoadingEditUpload(false);
    setEditedImageId(null);
  };

  const removeImageFromData = (imageId: string) =>
    setImageData((data) => {
      data.delete(imageId);
      return new Map(data);
    });

  const onDelete = async (imageId: string) => {
    spyClick(names.DeletePhotoModal.Delete, { containerId, fileId: imageId });
    const deletedImage = imageData.get(imageId);
    if (!deletedImage) return;
    const { originalId, editedId, thumbnailId } = deletedImage;
    const imageIds = [originalId, editedId, thumbnailId].filter(exists);
    removeImageFromData(imageId);
    reportImage(imageId, ImageReportValue.DELETE);
    imageApi.deleteImage({ executionId, containerId, imageIds });
  };

  const deleteImage = (imageId: string) => {
    modalManager.warning({
      title: t('flows.images.confirmQuestion'),
      message: t('flows.images.confirmMessage'),
      onConfirm: () => onDelete(imageId),
      onOpen: () => spyMount(names.DeletePhotoModal.self, { containerId, fileId: imageId }),
      onClose: () => spyUnmount(names.DeletePhotoModal.Close),
      icon: Trash,
      imgSrc: imageData?.get(imageId)?.originalBase64,
      labels: {
        confirm: t('common.delete'),
      },
    });
  };

  const state = useMemo<ItemPhotoContextState>(
    () => ({
      containerId,
      imageData: Array.from(imageData.values()),
      bulkUploadCounter: bulkImageDataRef.current.size,
      bulkUploadTotal: bulkImagesTotal,
      loadingUpload,
      loadingNewReport,
      uploadImage,
      uploadImages,
      openEditor,
      deleteImage,
    }),
    [loadingUpload, loadingNewReport, imageData],
  );

  return (
    <ItemPhotoContext.Provider value={state}>
      {children}
      {imageDataBeingEdited && (
        <React.Suspense>
          <ImageEditor
            containerId={containerId}
            imageId={editedImageId}
            imageSrc={imageDataBeingEdited.editedBase64 || imageDataBeingEdited.originalBase64}
            loading={loadingEditUpload}
            onClose={closeEditor}
            onClear={restoreOriginal}
            onEdit={onEdit}
          />
        </React.Suspense>
      )}
    </ItemPhotoContext.Provider>
  );
};

export function usePhotoContext() {
  return useContext(ItemPhotoContext);
}
