import { Text, Group, ThemeIcon, Button } from '@mantine/core';
import { FileText, CaretRight } from '@phosphor-icons/react';
import { ExecutionStatus } from '@flow/flow-backend-types';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { appApi } from 'stores/app/app.api';
import { toaster } from 'services/toaster';
import { names, useSpy } from 'services/espionage';
import { TitledExecution } from 'stores/flow';
import { useOnline } from 'stores/network';
import classes from './HistoryTabContent.module.css';

interface HistoryCardTitleProps {
  execution: TitledExecution;
  onShowPreview: (url: string, filename: string) => void;
}

export const testIds = {
  title: 'history-card-title',
  save: 'history-card-save',
};

export const HistoryCardTitle = ({ execution, onShowPreview }: HistoryCardTitleProps) => {
  const { t } = useTranslation();
  const { spyClick } = useSpy();
  const online = useOnline();
  const [loading, setLoading] = useState(false);
  const hasPdfPreview = execution.status === ExecutionStatus.done && Boolean(execution.reportId);

  const toastRequestPdfError = () => {
    toaster.error({
      title: t('flows.errors.failedRequestPdf.title'),
      message: t('flows.errors.failedRequestPdf.message'),
    });
  };

  const getFile = async () => {
    spyClick(names.HomePage.ShowPdf, { executionId: execution.id, flowName: execution.title });
    if (!online) return;
    setLoading(true);
    const fileBlob = await appApi.getBlobPdfFile(`${execution.id}/${execution.reportId}`);
    if (!fileBlob) {
      toastRequestPdfError();
      setLoading(false);
      return;
    }
    const url = URL.createObjectURL(fileBlob);
    const timeStamp = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const fileName = `${execution.title}-${timeStamp}.pdf`;
    onShowPreview(url, fileName);
    setLoading(false);
  };

  return (
    <Group justify='space-between' mb='tn' wrap='nowrap' align='flex-start' data-testid={testIds.title}>
      <Text size='lg' fz='md' c='cool.9' lh='sm' lineClamp={2}>
        {execution.title}
      </Text>
      {hasPdfPreview && (
        <Button
          variant='transparent'
          p={0}
          h={24}
          className={classes.button}
          loading={loading}
          loaderProps={{ size: 18, color: 'blue.6' }}
          disabled={!online}
          onClick={getFile}
          data-testid={testIds.save}
        >
          <ThemeIcon variant='subtle' color={online ? 'blue.6' : 'cool.3'}>
            <FileText size={24} />
          </ThemeIcon>
          <ThemeIcon variant='subtle' color={online ? 'blue.6' : 'cool.3'} ml='-tn'>
            <CaretRight size={18} />
          </ThemeIcon>
        </Button>
      )}
    </Group>
  );
};
