import { HashStraight } from '@phosphor-icons/react';
import { DropdownElementData, ReportValueType } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { ComboboxItem } from '@mantine/core';
import { DropdownEventProps } from '../../types';
import { EventBase, eventBaseTestIds } from '../../../EventBase';
import { DropdownEventDrawer, testIds as dropDownDrawerTestIds } from './DropdownEventDrawer';

export const testIds = {
  getEventTestId: (id: string) => `dropdown-event-${id}`,
  base: eventBaseTestIds,
  drawer: dropDownDrawerTestIds,
};

export const DropdownEvent = ({
  reportKey,
  uiEvent,
  valid,
  opened,
  lastReportedEvent,
  onCloseDrawer,
  onOpenDrawer,
  triggerReport,
}: DropdownEventProps) => {
  const elementData = uiEvent.elementData as DropdownElementData;
  const lastReportedValue = lastReportedEvent?.reportedValue;

  const options = useMemo(
    () =>
      elementData?.eventValues.map<ComboboxItem>(({ valueName, title: optionTitle }) => ({
        value: valueName,
        label: optionTitle ?? valueName,
      })) ?? [],
    [elementData],
  );

  const value = useMemo(() => {
    const option = elementData?.eventValues.find(({ valueName }) => valueName === lastReportedValue);
    return option ? (option.title ?? option.valueName) : undefined;
  }, [elementData, lastReportedValue]);

  const reportEvent = (newValue?: string) => {
    const shouldDeselect = lastReportedValue === newValue;
    if (shouldDeselect) {
      triggerReport({ reportedValue: null });
    } else {
      triggerReport({
        reportedValue: newValue,
        reportedValueType: ReportValueType.STRING,
      });
    }
  };

  return (
    <>
      <EventBase
        key={reportKey}
        child={uiEvent.isChild}
        title={uiEvent.title}
        mandatory={uiEvent.isMandatory}
        Icon={HashStraight}
        value={value}
        valid={valid}
        withVoice={uiEvent.tapToSpeakEnabled}
        onClick={onOpenDrawer}
        data-testid={testIds.getEventTestId(reportKey)}
      />
      <DropdownEventDrawer
        opened={opened}
        title={uiEvent.title}
        value={lastReportedValue ?? ''}
        options={options}
        valid={valid}
        onValuePicked={reportEvent}
        onDrawerClosed={onCloseDrawer}
      />
    </>
  );
};
