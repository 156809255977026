import { useMemo, useState } from 'react';

export interface FilterOption {
  value: string;
  label: string;
}

export function useSearchFilter(options: FilterOption[]) {
  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = useMemo(
    () => options.filter(({ label }) => label.toLowerCase().includes(searchValue.toLowerCase())),
    [searchValue, options],
  );

  return {
    searchValue,
    setSearchValue,
    filteredOptions,
  };
}
