import { Badge } from '@mantine/core';
import { ThemeVariant } from 'interfaces';

const colorVariants = {
  default: 'cool.6',
  success: 'emerald.6',
  warning: 'amber.6',
  danger: 'red.6',
};

export interface StatusBadgeProps {
  text: string;
  variant: ThemeVariant;
}

export const testIds = {
  container: 'badge-container',
};

export const StatusBadge = ({ variant, text }: StatusBadgeProps) => (
  <Badge
    variant='light'
    fz='xs'
    fw={600}
    radius='sm'
    color={colorVariants[variant]}
    className='capitalize'
    data-testid={testIds.container}
  >
    {text}
  </Badge>
);
