import { createStoreHook } from '@aiola/frontend';
import { ContainerId } from '@flow/flow-backend-types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { uiEventStore } from 'stores/uiEvent';
import { reportStore } from 'stores/report';
import { containerStore } from 'stores/container';
import { focusStore } from 'stores/focus';
import { processFilters } from './filters.utils';
import { Filters } from './filters.types';

const devtoolsOptions: DevtoolsOptions = {
  name: 'filters',
  store: 'filters',
  enabled: process.env.NODE_ENV === 'development',
};

export interface FilterState {
  filters: Filters;
  searchTerm: string;
  /** Either container without `childrenIds` or child container that match filters. */
  filteredContainerIds: Set<ContainerId>;
  /** Descendant children containers count that match filters */
  filteredContainerCounts: Record<ContainerId, number>;
}

interface FiltersActions {
  setFilters: (filters: Partial<Filters>) => void;
  setSearchTerm: (searchTerm: string) => void;
  filterContainers: () => void;
  reset: () => void;
}

const filtersInitialState: FilterState = {
  filters: {
    mandatory: false,
    missingMandatory: false,
    outOfBounds: false,
  },
  searchTerm: '',
  filteredContainerIds: new Set(),
  filteredContainerCounts: {},
};

export const filterStore = create(
  devtools(
    immer<FilterState & FiltersActions>((set, get) => ({
      ...filtersInitialState,
      setFilters: (filters = {}) => {
        set((state) => ({
          filters: { ...state.filters, ...filters },
        }));
        focusStore.getState().blurContainer();
      },
      setSearchTerm: (term: string) => {
        set((state) => {
          state.searchTerm = term;
        });
      },
      filterContainers: () => {
        const { filters, searchTerm } = get();
        const { rootContainerIds, containers, containerTemplatesMap, containerEventsMap } = containerStore.getState();
        const { uiEvents, visibilityBindings } = uiEventStore.getState();
        const { reports, boundedness, validity } = reportStore.getState();

        const { containerIds: filteredContainerIds, counts: filteredContainerCounts } = processFilters({
          rootContainerIds,
          containers,
          uiEvents,
          containerTemplatesMap,
          containerEventsMap,
          filters,
          searchTerm,
          reports,
          boundedness,
          validity,
          visibilityBindings,
        });
        set({ filteredContainerIds, filteredContainerCounts });
        containerStore.getState().closeAndClear();
        filteredContainerIds.forEach((containerId) => containerStore.getState().openContainerParents(containerId));
      },
      reset: () => {
        set(filtersInitialState);
      },
    })),
    devtoolsOptions,
  ),
);

export const useFilterStore = createStoreHook({ store: filterStore, useShallow });
