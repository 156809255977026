import { Box } from '@mantine/core';
import { useElementSize, useHeadroom } from '@mantine/hooks';
import cn from 'classnames';
import { useContainerStore } from 'stores/container';
import { useEffect } from 'react';
import classes from './InspectionHeaderWrapper.module.css';
import { FilterGroup } from '../FilterGroup/FilterGroup';
import { SearchBar } from '../SearchBar/SearchBar';
import { InspectionHeader } from '../InspectionHeader/InspectionHeader';
import { headerTestIds, searchBarTestIds, filterGroupTestIds } from '..';

export const testIds = {
  header: headerTestIds,
  searchBar: searchBarTestIds,
  filters: filterGroupTestIds,
};

export const InspectionHeaderWrapper = () => {
  const pinned = useHeadroom({ fixedAt: 100 });
  const { ref: headerRef, height: headerHeight } = useElementSize();
  const { ref: stickyRef, height: stickyHeight } = useElementSize();
  const { setScrollTop } = useContainerStore(['setScrollTop']);

  useEffect(() => {
    setScrollTop(headerHeight + stickyHeight);
  }, [headerHeight, stickyHeight]);

  return (
    <>
      <InspectionHeader ref={headerRef} />
      <Box
        ref={stickyRef}
        className={cn(classes.stickyWrapper, pinned ? classes.pinned : classes.unpinned)}
        top={headerHeight}
      >
        <SearchBar />
        <FilterGroup />
      </Box>
    </>
  );
};
