import { FlowId, FlowMetadataField, FlowMetadataFieldType, ReportedMetadataField } from '@flow/flow-backend-types';
import { Button, Divider, Stack, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { noop } from '@aiola/frontend';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Drawer, testIds as drawerTestIds } from 'components/Drawer/Drawer';
import { MetadataFormProvider, MetaValues } from 'contexts/MetadataFormContext';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { MetaType, useFlowMeta } from 'stores/inspectionMeta';
import { useUserDateFormat } from 'stores/settings';
import { useFlow } from 'stores/flow';
import { MetadataFormField, testIds as fieldTestIds } from './MetadataFormField';

interface MetadataDrawerProps {
  flowId: FlowId;
  opened: boolean;
  onClose: () => void;
  onSubmit: (fields: ReportedMetadataField[]) => Promise<void>;
}

export const testIds = {
  drawer: drawerTestIds,
  flowName: 'metadata-drawer-flow-name',
  submit: 'metadata-drawer-submit',
  field: fieldTestIds,
};

export const MetadataDrawer = ({ flowId, opened, onClose, onSubmit }: MetadataDrawerProps) => {
  const { t } = useTranslation();
  const { spyRef, spyClick } = useSpy();
  const flow = useFlow(flowId);
  const dateFormat = useUserDateFormat();
  const [loading, setLoading] = useState(false);
  const { metaFields } = useFlowMeta(MetaType.PRE, flowId);

  const getReportedValueFromInput = (value: MetaValues[string], field: FlowMetadataField) => {
    switch (field.type) {
      case FlowMetadataFieldType.Date:
        return dayjs(value as Date).format(dateFormat);
      case FlowMetadataFieldType.MultiselectDropdown:
        return JSON.stringify(value);
      default:
        return String(value);
    }
  };

  const onClickSubmit = () => spyClick(names.MetaPage.Submit, flow?.id);

  const handleSubmit = async (values: MetaValues) => {
    setLoading(true);
    const reportedFields = metaFields.map<ReportedMetadataField>((metaField) => ({
      id: metaField.id,
      order: metaField.order,
      value: getReportedValueFromInput(values[metaField.id], metaField),
      required: metaField.required,
      exposed: metaField.exposed,
    }));

    await onSubmit(reportedFields);
    setLoading(false);
  };

  useSpyOpened(spyRef, names.MetaPage.self, opened);

  return (
    <Drawer height='90vh' opened={opened} onClose={loading ? noop : onClose}>
      <Drawer.Header withBackButton>{t('meta.title')}</Drawer.Header>
      <Drawer.Body>
        <Drawer.Body.Sticky>
          <Title order={4} bg='white' py='lg' ta='center' data-testid={testIds.flowName}>
            {flow?.name}
          </Title>
        </Drawer.Body.Sticky>
        <MetadataFormProvider id={flow?.id} fields={metaFields} onSubmit={handleSubmit}>
          <Stack gap='xs' pb='md'>
            {metaFields.map((field) => (
              <MetadataFormField key={field.id} field={field} />
            ))}
          </Stack>
        </MetadataFormProvider>
      </Drawer.Body>
      <Divider />
      <Drawer.Footer>
        <Button type='submit' form={flow?.id} loading={loading} onClick={onClickSubmit} data-testid={testIds.submit}>
          {t('meta.submit')}
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};
