export const NewDynamicContainerLocation = {
  BUTTON: 'Add new',
  ICON: 'Empty list',
} as const;

export const ToggleContainerGroupStatus = {
  EXPAND: 'Expand',
  COLLAPSE: 'Collapse',
} as const;

export const HomePageTabOptions = {
  FORMS: 'froms',
  HISTORY: 'history',
} as const;

export const SelectForm = {
  JOIN: 'join',
  RESUME: 'resume',
} as const;
