import { Affix, Overlay, Stack, ThemeIcon, Title, Transition } from '@mantine/core';
import { LogoLoopSingle } from 'assets';
import { ZIndex } from 'consts';
import { useTranslation } from 'react-i18next';
import { useFlowByExecutionId, useFlowStore } from 'stores/flow';

interface InspectionLoaderProps {
  visible: boolean;
}

export const testIds = { logo: 'logo', title: 'title', loader: 'loader' };

export const InspectionLoader = ({ visible }: InspectionLoaderProps) => {
  const { t } = useTranslation();
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const flow = useFlowByExecutionId(currentExecutionId!);

  return (
    <Transition mounted={visible}>
      {(style) => (
        <>
          <Overlay style={style} color='#f0f0f0' blur={5} zIndex={ZIndex.AppLoader} />
          <Affix style={style} position={{ top: 0, left: 0, right: 0, bottom: 0 }} zIndex={ZIndex.AppLoader}>
            <Stack h='100vh' w='100%' className='overflow-hidden' justify='center' align='center'>
              <ThemeIcon variant='transparent' c='emerald.6' size={64}>
                <LogoLoopSingle
                  className='animate-[spin_1.5s_infinite_ease-in-out]'
                  width={64}
                  height='auto'
                  data-testid={testIds.loader}
                />
              </ThemeIcon>
              <Title order={4} fw={700} lh={1} w='100%' ta='center' textWrap='wrap'>
                {t('inspection.loaderText', { flowName: flow?.name })}
              </Title>
            </Stack>
          </Affix>
        </>
      )}
    </Transition>
  );
};
