import { ContainerId } from '@flow/flow-backend-types';
import { useFilterStore } from './filters.store';

export function useAreFiltersApplied() {
  const { filters } = useFilterStore(['filters']);
  return Object.values(filters).some(Boolean);
}

export function useIsSearchTermApplied() {
  const { searchTerm } = useFilterStore(['searchTerm']);
  return Boolean(searchTerm);
}

export function useSearchTerm() {
  return useFilterStore((state) => state.searchTerm);
}

/**
 * Find whether a given container should be rendered based on applied filters.
 * A container is considered visible if:
 * - No filters are applied
 * - Container matches filters directly
 * - Container has any descendants that match filters
 */
export function useVisibilityByFilters(containerId: ContainerId) {
  const { filteredContainerIds, filteredContainerCounts } = useFilterStore([
    'filteredContainerIds',
    'filteredContainerCounts',
  ]);
  const filtersAreApplied = useAreFiltersApplied();
  const searchIsApplied = useIsSearchTermApplied();
  const anyFiltersApplied = filtersAreApplied || searchIsApplied;

  return anyFiltersApplied ? filteredContainerIds.has(containerId) || filteredContainerCounts[containerId] > 0 : true;
}
