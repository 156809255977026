import { MultiSelect, NumberInput, Select, Textarea, TextInput } from '@mantine/core';
import { FlowMetadataField, FlowMetadataFieldType } from '@flow/flow-backend-types';
import { DatePickerInput } from '@mantine/dates';
import { memo } from 'react';
import { useMetadataFormContext } from 'contexts/MetadataFormContext';
import { useUserDateFormat } from 'stores/settings';

interface MetadataFormFieldProps {
  field: FlowMetadataField;
}

export const testIds = {
  getInputTestId: (id: string) => `metadata-form-field-input-${id}`,
};

export const MetadataFormField = memo<MetadataFormFieldProps>(({ field }) => {
  const { getInputProps, getValues } = useMetadataFormContext();
  const dateFormat = useUserDateFormat();

  const props = {
    ...getInputProps(field.id),
    label: field.title,
    placeholder: field.placeholder,
    withAsterisk: field.required,
    'data-testid': testIds.getInputTestId(field.id),
  };

  switch (field?.type) {
    case FlowMetadataFieldType.String:
      return <TextInput {...props} />;
    case FlowMetadataFieldType.Text:
      return <Textarea {...props} />;
    case FlowMetadataFieldType.Number:
      return <NumberInput inputMode='decimal' decimalScale={5} {...props} />;
    case FlowMetadataFieldType.Dropdown:
      return <Select searchable data={field.options} {...props} />;
    case FlowMetadataFieldType.MultiselectDropdown:
      return (
        <MultiSelect
          searchable
          data={field.options}
          {...props}
          placeholder={(getValues()[field.id] as string[]).length ? '' : field.placeholder}
        />
      );
    case FlowMetadataFieldType.Date:
      return <DatePickerInput allowDeselect clearable valueFormat={dateFormat} {...props} />;
    default:
      return null;
  }
});
