import { ActionIcon, Avatar, Card, MantineSize, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { UserIdentity } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { useCurrentUser } from 'stores/auth';
import { UserAvatar } from '../UserAvatar';
import { UserAvatarPopover, testIds as UserAvatarPopoverTestIds } from '../UserAvatarPopover/UserAvatarPopover';

interface UserAvatarGroupProps {
  users: UserIdentity[];
  limit?: number;
  size?: MantineSize | number | string;
  withPopover?: boolean;
}

export const testIds = {
  group: 'user-avatar-group',
  restUsersAvatar: 'user-avatar-rest',
  popover: UserAvatarPopoverTestIds,
  chevron: 'user-avatar-chevron',
  card: 'user-avatar-card',
  userAvatar: (userId: string) => `user-avatar-${userId}`,
};

export const UserAvatarGroup = ({ users, limit = 0, size = rem(32), withPopover = true }: UserAvatarGroupProps) => {
  const [popoverOpened, { toggle: togglePopover }] = useDisclosure();
  const currentUser = useCurrentUser()!;

  const sortedUsers = useMemo(() => {
    const otherUsers = users.filter(({ userId }) => userId !== currentUser.userId);
    return otherUsers.length === users.length ? users : [currentUser, ...otherUsers];
  }, [users, currentUser]);

  const usersToRenderAsAvatar = useMemo(
    () => (limit ? sortedUsers.slice(0, limit) : sortedUsers),
    [sortedUsers, limit],
  );

  const shouldRenderRestUsers = !!limit && users.length > limit;

  return (
    <UserAvatarPopover users={users} disabled={!withPopover} onChange={togglePopover}>
      <Card
        padding='tiny'
        radius='xl'
        shadow='none'
        bg={popoverOpened ? 'cool.7' : 'gray.3'}
        withBorder={false}
        tabIndex={0}
        data-testid={testIds.card}
      >
        <Avatar.Group spacing='tn' data-testid={testIds.group}>
          {usersToRenderAsAvatar.map((user, index) => (
            <UserAvatar
              key={user?.userId}
              user={user!}
              size={size}
              style={{ zIndex: usersToRenderAsAvatar.length - index, border: 'none' }}
              data-testid={testIds.userAvatar(user?.userId!)}
            />
          ))}
          {shouldRenderRestUsers && (
            <Avatar
              className='border-none'
              size={size}
              color='white'
              bg='cool.5'
              fw={500}
              data-testid={testIds.restUsersAvatar}
            >
              {sortedUsers.length - limit}+
            </Avatar>
          )}
          {withPopover && (
            <ActionIcon
              mt={2}
              variant='transparent'
              color={popoverOpened ? 'gray.3' : 'cool.7'}
              data-testid={testIds.chevron}
            >
              {popoverOpened ? <CaretUp size={12} weight='bold' /> : <CaretDown size={12} weight='bold' />}
            </ActionIcon>
          )}
        </Avatar.Group>
      </Card>
    </UserAvatarPopover>
  );
};
