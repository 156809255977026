import { Flex, ScrollArea } from '@mantine/core';
import { Filters, useFilterStore } from 'stores/filters';
import { Chip } from 'components';
import i18n from 'services/i18n';

export type FilterKey = keyof Filters | 'all';

export const testIds = {
  filtersGroup: 'header-filters',
  getFilterTestId: (filter: FilterKey) => `header-filter-${filter}`,
};

const filterTitles = {
  all: i18n.t('inspection.header.filters.all'),
  mandatory: i18n.t('inspection.header.filters.mandatory'),
  missingMandatory: i18n.t('inspection.header.filters.missingMandatory'),
  outOfBounds: i18n.t('inspection.header.filters.outOfBounds'),
};

export const FilterGroup = () => {
  const { filters, setFilters, filterContainers } = useFilterStore(['filters', 'setFilters', 'filterContainers']);

  const handleFilterClick = (key: FilterKey) => () => {
    if (key === 'all') setFilters({ mandatory: false, missingMandatory: false, outOfBounds: false });
    else setFilters({ [key]: !filters[key] });
    filterContainers();
  };

  const isAllActive = Object.values(filters).every((value) => !value);

  const getChipValue = isAllActive
    ? ['all']
    : (Object.keys(filters) as (keyof Filters)[]).filter((key) => filters[key]);

  return (
    <ScrollArea py='xs' type='never' data-testid={testIds.filtersGroup}>
      <Chip.Group multiple value={getChipValue}>
        <Flex display='inline-flex' gap='sm' mx='xs'>
          {Object.entries(filterTitles).map(([key, label]) => (
            <Chip
              className='font-medium'
              key={key}
              value={key}
              size='md'
              onClick={handleFilterClick(key as FilterKey)}
              data-testid={testIds.getFilterTestId(key as FilterKey)}
            >
              {label}
            </Chip>
          ))}
        </Flex>
      </Chip.Group>
    </ScrollArea>
  );
};
