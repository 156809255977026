import { Icon as IconComponent, Microphone } from '@phosphor-icons/react';
import { Card, CardProps, Flex, Paper, Stack, Text } from '@mantine/core';
import { memo, PropsWithChildren } from 'react';
import cn from 'classnames';
import { EventValidationIcon, testIds as validationIconTestIds } from './EventValidationIcon';

type EventBaseVariant = 'light' | 'shaded';
interface EventBaseProps extends PropsWithChildren {
  title: string;
  mandatory?: boolean;
  Icon: IconComponent;
  valid?: boolean;
  bounded?: boolean;
  error?: string;
  withVoice?: boolean;
  /** If provided, will be displayed in a dedicated input element. Cannot be used if `children` are passed. */
  value?: string | null;
  child?: boolean;
  onClick?: () => void;
}

export const testIds = {
  wrapper: 'event-wrapper',
  title: 'event-title',
  validation: validationIconTestIds,
  voice: 'event-voice-icon',
  value: 'event-value-field',
  valueText: 'event-value-text',
  mandatory: 'event-mandatory-asterisk',
  errorMsg: 'event-error-msg',
};

const cardVariants: Record<EventBaseVariant, CardProps> = {
  light: {
    shadow: 'xs',
    bg: 'white',
  },
  shaded: {
    shadow: 'zero',
    bg: 'transparent',
    withBorder: false,
  },
};

export const EventBase = memo<EventBaseProps>(
  ({ title, mandatory, Icon, valid, bounded, error, withVoice, value, child, children, ...props }) => {
    const variant: EventBaseVariant = child ? 'shaded' : 'light';
    const hasValue = Boolean(children) || Boolean(value);
    const hasError = bounded === false;
    const textSize = hasValue ? 'sm' : 'lg';

    return (
      <Card
        {...cardVariants[variant]}
        py={hasValue ? 'tn' : 'lg'}
        px='tn'
        radius='md'
        className={cn({ 'border-invalid': hasError, 'border-white': !hasError })}
        data-testid={testIds.wrapper}
        data-parent-event={!child}
        {...props}
      >
        <Flex w='100%' gap='tn' justify='space-around'>
          <Flex miw={0} flex={1} gap='teeny' align='center'>
            <Icon className='shrink-0' size={20} weight='duotone' />
            <Text size={textSize} pl='tn' truncate data-testid={testIds.title}>
              {title}
            </Text>
            {mandatory && (
              <Text size={textSize} c='red' data-testid={testIds.mandatory}>
                *
              </Text>
            )}
          </Flex>
          <Flex align='center'>
            <EventValidationIcon valid={valid} bounded={bounded} />
            {withVoice && <Microphone size={20} data-testid={testIds.voice} />}
          </Flex>
        </Flex>
        {hasValue && (
          <Stack ml={20} gap={2}>
            <Paper
              shadow='zero'
              p='tiny'
              mt='tn'
              bg={hasError ? 'red.1' : variant === 'light' ? 'gray.1' : 'white'}
              data-testid={testIds.value}
            >
              {children ?? (
                <Text
                  className='whitespace-pre-wrap'
                  lineClamp={3}
                  pl='tiny'
                  c={hasError ? 'red' : 'black'}
                  data-testid={testIds.valueText}
                >
                  {value}
                </Text>
              )}
            </Paper>
            {hasError && (
              <Text c='red.5' size='xs' data-testid={testIds.errorMsg}>
                {error}
              </Text>
            )}
          </Stack>
        )}
      </Card>
    );
  },
);
