import qs from 'qs';
import {
  CancelExecutionResponse,
  FinishExecutionRequest,
  FinishExecutionResponse,
  Flow,
  GetExecutionsResponse,
  GetFlowsResponse,
  JoinExecutionRequest,
  JoinExecutionResponse,
  ReviewExecutionRequest,
  ReviewExecutionResponse,
  StartExecutionRequest,
  StartExecutionResponse,
} from '@flow/flow-backend-types';
import { HermesResponse } from '@aiola/frontend';
import { hermes } from 'services/hermes';
import { config } from 'services/config';
import { Execution, ExecutionStatus } from './flow.types';

const flowApiUrl = config.getApiUrl();

export const flowsApi = {
  fetchFlows: async (): Promise<Flow[] | undefined> => {
    const { data } = await hermes.get<GetFlowsResponse>(`${flowApiUrl}/flows`);
    return data?.items.filter((flow) => !!flow.activeVersion);
  },

  getExecutionById: async (flowExecutionId: string): Promise<Execution | undefined> => {
    const { data } = await hermes.get<Execution>(`${flowApiUrl}/executions/${flowExecutionId}`);
    return data;
  },

  /**
   * Fetch all executions for a given status.
   * @returns On success, returns an array of all fetched.
   * @throws If any request fails.
   */
  fetchExecutionsByStatus: async (
    status: ExecutionStatus,
    minFinishDate?: number,
    userId?: string,
  ): Promise<Execution[]> => {
    const executions: Execution[] = [];
    let nextContinuationToken: string | undefined;
    do {
      const queryString = {
        status,
        nextContinuationToken,
        minFinishDate,
        userId,
      };

      const url = `${flowApiUrl}/executions?${qs.stringify(queryString)}`;
      const { data, error } = await hermes.get<GetExecutionsResponse>(url);
      if (!data) throw new Error(`Failed to fetch executions with status ${status}`, { cause: error });
      executions.push(...data.executions);
      nextContinuationToken = data.nextContinuationToken;
    } while (nextContinuationToken);

    return executions;
  },

  startExecution: async (executionScheme: StartExecutionRequest): Promise<HermesResponse<Execution>> =>
    hermes.put<StartExecutionResponse>(`${flowApiUrl}/executions/start`, executionScheme),

  reviewExecution: async (reviewExecutionRequest: ReviewExecutionRequest): Promise<HermesResponse<Execution>> =>
    hermes.patch<ReviewExecutionResponse>(`${flowApiUrl}/executions/review`, reviewExecutionRequest),

  continueExecution: async (reviewExecutionRequest: ReviewExecutionRequest): Promise<HermesResponse<Execution>> =>
    hermes.patch<ReviewExecutionResponse>(`${flowApiUrl}/executions/continue`, reviewExecutionRequest),

  joinExecution: async (joinExecutionRequest: JoinExecutionRequest) => {
    const { data } = await hermes.put<JoinExecutionResponse>(`${flowApiUrl}/executions/join`, joinExecutionRequest);
    return data;
  },

  finishExecution: async (flowExecutionId: string) => {
    const body: FinishExecutionRequest = {
      flowExecutionId,
      postInspectionMetadata: [],
    };
    const { data } = await hermes.patch<FinishExecutionResponse>(`${flowApiUrl}/executions/finish`, body);
    return !!data;
  },

  cancelExecution: async (flowExecutionId: string) => {
    const { data } = await hermes.patch<CancelExecutionResponse>(`${flowApiUrl}/executions/cancel`, {
      flowExecutionId,
    });
    return !!data;
  },
};
