import { Flow, StartExecutionRequest } from '@flow/flow-backend-types';
import { getMockUser, MOCK_USER } from 'stores/auth';
import { generateId } from '@aiola/frontend';
import { Execution } from './flow.types';

export const MOCK_FLOW_ID = 'flow-1';
export const MOCK_EXECUTION_ID = 'MOCK_EXECUTION_ID';
export const MOCK_FLOW_VERSION = 'flow-version-1';

export const MOCK_OTHER_FLOW_USER = getMockUser({ userId: 'other-user-id' });

const EARLY_DATE = Date.now() - 1000 * 60 * 60 * 24 * 2;
const LATE_DATE = Date.now();

export const getFlowMock = (overrides?: Partial<Flow>): Flow => ({
  id: generateId(),
  name: 'Mazda',
  activeVersion: 'test-version',
  description: 'Daily roundup of production floor',
  singleInstance: false,
  maxInspectors: 2,
  order: 0,
  dateFormat: 'DD/MM/YYYY',
  folderId: null,
  voiceInputEnabled: true,
  ...overrides,
});

export const getStartExecutionRequestMock = (overrides?: Partial<StartExecutionRequest>): StartExecutionRequest => ({
  flowId: generateId(),
  version: MOCK_FLOW_VERSION,
  preInspectionMetadata: [],
  uniqueIdentifier: 'Execution Mock',
  ...overrides,
});

export const getExecutionMock = (overrides?: Partial<Execution>): Execution => ({
  id: generateId(),
  flowRef: {
    id: generateId(),
    version: MOCK_FLOW_VERSION,
  },
  createdBy: MOCK_USER,
  status: 'inProgress',
  createdAt: Date.now(),
  joinedUsers: [MOCK_USER],
  workedOn: [MOCK_USER],
  originalTZName: 'UTC',
  reportId: generateId(),
  uniqueIdentifier: 'Execution Mock',
  ...overrides,
});

export const flowsMockArray = [
  getFlowMock({
    id: 'flow-1',
    name: 'Mazda',
    activeVersion: 'test-version',
    singleInstance: true,
    maxInspectors: 1,
  }),
  getFlowMock({
    id: 'flow-2',
    name: 'Toyota',
    activeVersion: 'test-version',
    singleInstance: true,
    maxInspectors: 3,
  }),
  getFlowMock({
    id: 'flow-3',
    name: 'Mercedes',
    activeVersion: 'test-version',
    singleInstance: true,
    maxInspectors: 1,
  }),
];

export const getOngoingExecutions = (overrides?: Partial<Execution>): Execution[] => [
  getExecutionMock({
    id: 'flowExecutionId-1',
    createdAt: EARLY_DATE,
    flowRef: {
      id: flowsMockArray[0].id,
      version: flowsMockArray[0].activeVersion,
    },
    createdBy: MOCK_USER,
    ...overrides,
  }),
];

export const getAvailableExecutions = (overrides?: Partial<Execution>): Execution[] => [
  getExecutionMock({
    id: 'flowExecutionId-2',
    flowRef: {
      id: flowsMockArray[1].id,
      version: flowsMockArray[1].activeVersion,
    },
    createdAt: EARLY_DATE,
    createdBy: MOCK_OTHER_FLOW_USER,
    ...overrides,
  }),
  getExecutionMock({
    id: 'flowExecutionId-3',
    flowRef: {
      id: flowsMockArray[1].id,
      version: flowsMockArray[1].activeVersion,
    },
    createdAt: LATE_DATE,
    createdBy: MOCK_OTHER_FLOW_USER,
    joinedUsers: [MOCK_OTHER_FLOW_USER],
    workedOn: [MOCK_OTHER_FLOW_USER],
    ...overrides,
  }),
];
export const getNonJoinableInProgressExecutions = (overrides?: Partial<Execution>): Execution[] => [
  getExecutionMock({
    id: 'flowExecutionId-4',
    createdAt: LATE_DATE,
    flowRef: {
      id: flowsMockArray[2].id,
      version: flowsMockArray[2].activeVersion,
    },
    createdBy: MOCK_OTHER_FLOW_USER,
    joinedUsers: [MOCK_OTHER_FLOW_USER],
    workedOn: [MOCK_OTHER_FLOW_USER],
    ...overrides,
  }),
];
export const getCompletedExecutions = (overrides?: Partial<Execution>): Execution[] => [
  getExecutionMock({
    id: 'flowExecutionId-5',
    status: 'done',
    createdAt: EARLY_DATE,
    flowRef: {
      id: flowsMockArray[2].id,
      version: flowsMockArray[2].activeVersion,
    },
    createdBy: MOCK_USER,
    ...overrides,
  }),
];

export const executionsMockArray = [
  ...getOngoingExecutions(),
  ...getAvailableExecutions(),
  ...getNonJoinableInProgressExecutions(),
  ...getCompletedExecutions(),
];

export const inProgressExecution = (): Execution => ({
  flowRef: {
    id: MOCK_FLOW_ID,
    version: MOCK_FLOW_VERSION,
  },
  id: 'test-execution-id',
  uniqueIdentifier: 'unique-identifier',
  createdBy: MOCK_USER,
  status: 'inProgress',
  createdAt: 1688469386939,
  joinedUsers: [],
  workedOn: [],
  originalTZName: 'UTC',
});
