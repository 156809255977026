import { Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { MagnifyingGlass } from '@phosphor-icons/react/dist/ssr';
import { Microphone, Plus } from '@phosphor-icons/react';
import { Trans, useTranslation } from 'react-i18next';
import { LogoLoop } from 'assets';
import { useContainerStore } from 'stores/container';
import { useAreFiltersApplied, useFilterStore, useIsSearchTermApplied } from 'stores/filters';
import { useVoiceFirstAvailability } from '../InspectionToolbar/InspectionToolbar.hooks';

export const testIds = {
  wrapper: 'inspection-empty-state-wrapper',
  emptyListIcon: 'inspection-empty-list-icon',
  noResultsIcon: 'inspection-no-results-icon',
  title: 'inspection-empty-state-title',
  text: 'inspection-empty-state-text',
};

export const InspectionEmptyState = () => {
  const { t } = useTranslation();
  const { rootContainerIds } = useContainerStore(['rootContainerIds']);
  const isVoiceFirstEnabled = useVoiceFirstAvailability();
  const { filteredContainerIds, filteredContainerCounts } = useFilterStore([
    'filteredContainerIds',
    'filteredContainerCounts',
  ]);
  const filtersAreApplied = useAreFiltersApplied();
  const searchIsApplied = useIsSearchTermApplied();
  const anyFiltersApplied = filtersAreApplied || searchIsApplied;
  const anyContainerAreFiltered = filteredContainerIds.size > 0 || Object.values(filteredContainerCounts).some(Boolean);

  const emptyBecauseFilters = anyFiltersApplied && !anyContainerAreFiltered;
  const emptyBecauseContainers = rootContainerIds.length === 0;
  const empty = emptyBecauseFilters || emptyBecauseContainers;

  if (!empty) return null;

  const renderIcon = () => {
    if (emptyBecauseFilters) {
      return (
        <ThemeIcon variant='transparent' color='cool.4' size={48}>
          <MagnifyingGlass weight='duotone' size={48} data-testid={testIds.noResultsIcon} />
        </ThemeIcon>
      );
    }
    return (
      <LogoLoop color='var(--mantine-color-cool-4)' height={48} width='auto' data-testid={testIds.emptyListIcon} />
    );
  };

  const renderTitle = () =>
    emptyBecauseFilters ? t('inspection.emptyFilterResult.title') : t('inspection.emptyContainerList.title');

  const renderText = () => {
    if (emptyBecauseFilters) return null;

    return isVoiceFirstEnabled ? (
      <Trans
        t={t}
        i18nKey='inspection.emptyContainerList.textWithVoice'
        components={{
          1: <Microphone size={16} weight='fill' className='-mb-[2px]' />,
          2: <Plus size={16} className='-mb-[2px]' />,
        }}
      />
    ) : (
      <Trans
        t={t}
        i18nKey='inspection.emptyContainerList.textWithoutVoice'
        components={{
          1: <Plus size={16} className='-mb-[2px]' />,
        }}
      />
    );
  };

  return (
    <Stack pt='10vh' align='center' gap='xs' data-testid={testIds.wrapper}>
      {renderIcon()}
      <Title order={6} data-testid={testIds.title}>
        {renderTitle()}
      </Title>
      <Text ta='center' size='md' maw='80vw' fw={400} data-testid={testIds.text}>
        {renderText()}
      </Text>
    </Stack>
  );
};
