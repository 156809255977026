import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { useOpenedEvent } from 'stores/uiEvent';
import { useVoiceStore } from 'stores/voice';

export const testIds = {
  button: 'toolbar-done-action-button',
};

export const ToolbarDoneAction = () => {
  const { t } = useTranslation();
  const { spyClick } = useSpy();
  const { setOpenedEvent } = useOpenedEvent();
  const setContext = useVoiceStore((state) => state.internal.setContext);

  const onClick = () => {
    spyClick(names.ContextReportingModal.Done);
    setOpenedEvent();
    setContext(); // for exiting text events, until new voice api
  };

  return (
    <Button color='cool.7' size='md' flex={1} h={44} onClick={onClick} data-testid={testIds.button}>
      {t('inspection.toolbar.done.title')}
    </Button>
  );
};
