import { useTranslation } from 'react-i18next';
import { Drawer, EmptyState } from 'components';
import { Ear, EarSlash, MicrophoneStage } from '@phosphor-icons/react';
import { Box, Timeline } from '@mantine/core';
import dayjs from 'dayjs';
import cn from 'classnames';
import { useDistraction } from 'stores/app';
import { useEffect, useRef } from 'react';
import { useTranscripts } from './InspectionToolbar.hooks';

export const testIds = {};

export const TranscriptDrawer = () => {
  const { t } = useTranslation();
  const transcripts = useTranscripts();
  const { distracting: opened, concentrate: onClose } = useDistraction('transcript');
  const scrollAnchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollAnchorRef.current) scrollAnchorRef.current.scrollIntoView();
  }, [transcripts]);

  return (
    <Drawer opened={opened} height='70vh' variant='grayed' onClose={onClose}>
      <Drawer.Header>{t('voice.transcript.title')}</Drawer.Header>
      <Drawer.Body>
        <EmptyState
          hidden={Boolean(transcripts.length)}
          title={t('voice.transcript.placeholder')}
          icon={<MicrophoneStage />}
        />
        <Timeline active={transcripts.length - 1} color='amber' bulletSize={30} pb={80}>
          {transcripts.map((transcript) => (
            <Timeline.Item
              key={transcript.id}
              classNames={{
                itemTitle: cn('text-xs text-default mb-0', { 'pt-2': !transcript.transcript }),
                itemContent: cn({ hidden: !transcript.transcript }),
              }}
              className='last'
              bullet={transcript.status === 'listening' ? <Ear /> : <EarSlash />}
              title={dayjs(transcript.timestamp).format('HH:mm:ss')}
            >
              {transcript.transcript || (transcript.status === 'listening' && '...')}
            </Timeline.Item>
          ))}
        </Timeline>
        <Box ref={scrollAnchorRef} />
      </Drawer.Body>
    </Drawer>
  );
};
