import { Box, ScrollAreaProps, ScrollArea } from '@mantine/core';
import { PropsWithChildren } from 'react';

interface DrawerBodyProps extends PropsWithChildren, ScrollAreaProps {}

export const testIds = {
  body: 'drawer-body',
  sticky: 'drawer-body-sticky',
};

export const DrawerBody = ({ children, ...props }: DrawerBodyProps) => (
  <ScrollArea pos='relative' px='md' flex={1} data-testid={testIds.body} {...props}>
    {children}
  </ScrollArea>
);

const DrawerBodySticky = ({ children }: DrawerBodyProps) => (
  <Box className='z-10' pos='sticky' top={0} pb='sm' data-testid={testIds.sticky}>
    {children}
  </Box>
);

DrawerBody.Sticky = DrawerBodySticky;
