import { hermes } from 'services/hermes';
import { CreateImageArgs, DeleteImageArgs, UpdateImageArgs } from './imageApi.types';
import { b64toBlob, getImageUrl, getImageCreationUrl } from './imageApi.utils';

const Authorization = import.meta.env.VITE_USER_TEMPORARY_JWT_TOKEN;

export const imageApi = {
  createImage: async ({
    executionId,
    containerId,
    base64Image,
    originalFileId,
    fileUsage,
  }: CreateImageArgs): Promise<string | null> => {
    const url = getImageCreationUrl(executionId, containerId, originalFileId);
    const blob = b64toBlob(base64Image);
    const formData = new FormData();
    formData.append('file', blob, 'image.jpg');
    formData.append('fileUsage', fileUsage);
    const { data } = await hermes.put<{ fileId: string }>(url, formData, {
      config: { headers: { Authorization } },
      fallback: { fileId: '' },
    });
    return data.fileId;
  },
  deleteImage: async ({ executionId, containerId, imageIds }: DeleteImageArgs): Promise<boolean> => {
    const urls = imageIds.map((imageId) => getImageUrl(imageId, executionId, containerId));
    const promises = urls.map((url) => hermes.delete(url, { config: { headers: { Authorization } } }));
    const results = await Promise.all(promises);
    return results.every((result) => result.data);
  },
  updateImage: async ({
    imageId,
    executionId,
    containerId,
    base64Image,
    originalFileId,
    fileUsage,
  }: UpdateImageArgs): Promise<string | null> => {
    if (imageId) {
      const deleted = await imageApi.deleteImage({ executionId, containerId, imageIds: [imageId] });
      if (!deleted) return null;
    }
    return imageApi.createImage({ executionId, containerId, base64Image, originalFileId, fileUsage });
  },
};
