import { FlowMetadataField, FlowMetadataFieldType } from '@flow/flow-backend-types';
import { createFormContext, isNotEmpty } from '@mantine/form';
import { PropsWithChildren, useEffect } from 'react';

export type MetaValues = Record<string, string | string[] | number | Date>;
const [MetadataFormContextProvider, useMetadataFormContext, useMetadataForm] = createFormContext<MetaValues>();

interface MetadataFormProviderProps extends PropsWithChildren {
  id?: string;
  fields: FlowMetadataField[];
  onSubmit: (values: MetaValues) => void;
}

function getInitialValues(fields: FlowMetadataField[]) {
  return fields.reduce((acc, field) => {
    if (field.type === FlowMetadataFieldType.MultiselectDropdown) acc[field.id] = [];
    else if (field.type === FlowMetadataFieldType.Date) return acc;
    else acc[field.id] = '';
    return acc;
  }, {} as MetaValues);
}

function getValidation(fields: FlowMetadataField[]) {
  return fields.reduce<Record<string, (value: any) => any>>((acc, field) => {
    if (field.required) acc[field.id] = isNotEmpty();
    return acc;
  }, {});
}

export const MetadataFormProvider = ({ children, id, fields, onSubmit }: MetadataFormProviderProps) => {
  const form = useMetadataForm({
    validate: getValidation(fields),
    initialValues: getInitialValues(fields),
  });

  useEffect(
    () => () => {
      form.reset();
    },
    [],
  );

  return (
    <MetadataFormContextProvider form={form}>
      <form id={id} onSubmit={form.onSubmit(onSubmit)}>
        {children}
      </form>
    </MetadataFormContextProvider>
  );
};

export { useMetadataFormContext };
