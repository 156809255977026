import { useTimeout } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { Center } from '@mantine/core';
import { LogoLoop } from 'assets';
import { useAppStore } from 'stores/app';
import { useFlowStore } from 'stores/flow';
import { useOnline } from 'stores/network';
import { AnimationStage, useHomePageLoad } from './HomePageLoadContext';

// when animating the page, display loader fr at least 1s, and until all data is loaded
export const HomePageLoader = () => {
  const online = useOnline();
  const { isLoadingFlows, isLoadingExecutions } = useFlowStore(['isLoadingFlows', 'isLoadingExecutions']);
  const { syncCount } = useAppStore(['syncCount']);
  const { animationStage, finishAnimationStage } = useHomePageLoad(AnimationStage.loader);
  const [canUnmount, setCanUnmount] = useState(false);
  const firstSyncDone = syncCount.flows > 0 && syncCount.executions > 0;
  const isLoadingFromCache = isLoadingFlows || isLoadingExecutions;
  const isLoading = online ? !firstSyncDone : isLoadingFromCache;
  useTimeout(() => setCanUnmount(true), 1000, { autoInvoke: true });

  useEffect(() => {
    if (!isLoading && canUnmount) finishAnimationStage();
  }, [isLoading, canUnmount]);

  return (
    animationStage <= AnimationStage.loader && (
      <Center pos='absolute' w='100%' top='50%' className='-translate-y-1/2'>
        <LogoLoop width='300' height='auto' color='white' className='animate-spin' />
      </Center>
    )
  );
};
