import { LogoLoop } from 'assets';
import { useDebugModeToggle, useHandleNewAppVersion } from 'hooks';
import { useEffect, useState } from 'react';
import { AppRoutes } from 'routes';
import { names, useSpy } from 'services/espionage';
import { logger } from 'services/logger';
import { useMixpanel } from 'services/mixpanel';
import { useWakeLock } from 'services/wakeLock';
import { useAuthStore } from 'stores/auth';

export const testIds = {
  splash: 'splash-screen',
};

const SplashScreen = () => (
  <div
    className='w-screen h-screen bg-[#059669] flex flex-col items-center justify-center'
    data-testid={testIds.splash}
  >
    <LogoLoop width='300' height='auto' color='white' className='animate-spin' />
  </div>
);

const App = () => {
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const { getCurrentUser, currentUser, createSession } = useAuthStore([
    'getCurrentUser',
    'currentUser',
    'createSession',
  ]);
  const { spyMount } = useSpy();
  useWakeLock(isAppInitialized);
  useMixpanel();
  useDebugModeToggle();
  useHandleNewAppVersion();

  useEffect(() => {
    (async () => {
      await createSession();
      await getCurrentUser();
      setIsAppInitialized(true);
      const { pdfjs } = await import('react-pdf');
      pdfjs.GlobalWorkerOptions.workerSrc = '/worker/pdf.worker.min.js';
      logger.info('App initialized');
    })();
    spyMount(names.App.Initialization);
  }, []);

  useEffect(() => {
    if (currentUser) {
      logger.setUser({ userId: currentUser.userId, userEmailAddress: currentUser.email, userName: currentUser.name });
      logger.info('User data set');
    }
  }, [currentUser]);

  return isAppInitialized ? <AppRoutes /> : <SplashScreen />;
};

export default App;
