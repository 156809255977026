import { FC, useEffect, useMemo, useState } from 'react';
import { useAppStore } from 'stores/app';
import { useContainerStore } from 'stores/container';
import { useAuthStore, useCurrentUser } from 'stores/auth';
import { Execution, useExecution, useFlowStore } from 'stores/flow';
import {
  ReportCollectionKey,
  useReportStore,
  getReportCollectionKey,
  pullLastReport,
  getLastReportedValues,
} from 'stores/report';
import { useFilterStore } from 'stores/filters';
import {
  isEventVisibleByBinding,
  splitCEBridgeId,
  useGetCEBridges,
  useGetContainerStaticEvents,
  useGetUiEvent,
  useUiEventStore,
  AnyBridge,
  isEventReported,
} from 'stores/uiEvent';
import { useVoiceStore } from 'stores/voice';
import { ROUTES } from 'routes/routes.config';
import { toaster } from 'services/toaster';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { useForceNavigate } from 'hooks/useForceNavigate';
import {
  Container,
  ContainerTypeId,
  ExecutionId,
  ExecutionStatus,
  UiEvent,
  UserIdentity,
} from '@flow/flow-backend-types';
import { ApplicabilityReportValue } from '@jargonic/event-definition-types';
import { exists } from 'utils';
import { config } from 'services/config';
import { useTimeout } from '@mantine/hooks';
import { useOnline } from 'stores/network';
import { flowsApi } from 'stores/flow/flow.api';
import { IconCircleX, IconClockX, IconDeviceImacSearch, IconFileCheck, IconProps } from '@tabler/icons-react';
import { modalManager } from 'services/modalManager';
import { Flex, Text } from '@mantine/core';
import { UserAvatar } from 'components';

export function useExecutionBlocked(execution?: Execution) {
  const { currentUser } = useAuthStore(['currentUser']);
  const navigate = useForceNavigate();

  useEffect(() => {
    const executionStatus = execution?.status;
    const isExecutionInReview = executionStatus === 'inReview';
    const isReviewedByCurrentUser = execution?.reviewedBy?.userId === currentUser?.userId;

    if (isExecutionInReview && isReviewedByCurrentUser) {
      navigate(ROUTES.REVIEW_INSPECTION(execution?.id));
    }
  }, [execution]);
}

const MIN_LOAD_TIME = 1500;

export function useStartInspection(executionId: string) {
  const execution = useExecution(executionId);
  const navigate = useForceNavigate();
  const { spyMount } = useSpy();
  const { loadRenderModel, inspectionDataLoading, setInspectionDataLoading } = useAppStore([
    'loadRenderModel',
    'inspectionDataLoading',
    'setInspectionDataLoading',
  ]);
  const { loadExecutionReports } = useReportStore(['loadExecutionReports']);
  const { currentExecutionId, setCurrentExecutionId } = useFlowStore(['currentExecutionId', 'setCurrentExecutionId']);
  const { closeAndClear } = useContainerStore(['closeAndClear']);
  const { reset: resetVoice } = useVoiceStore(['reset']);

  const [minWaitDone, setMinWaitDone] = useState(false);
  useTimeout(() => setMinWaitDone(true), MIN_LOAD_TIME, { autoInvoke: true });

  useEffect(() => {
    const loadData = async () => {
      if (execution && !currentExecutionId) {
        setInspectionDataLoading(true);
        setCurrentExecutionId(executionId);
        const renderModelResponse = await loadRenderModel(execution.flowRef.id, execution.flowRef.version, executionId);

        if (!renderModelResponse) {
          navigate(ROUTES.HOME);
          spyMount(names.ExecutionPage.RenderModelError);
          toaster.error({ title: 'Could not load inspection data.', message: null });
          return;
        }
        await loadExecutionReports(executionId);
        setInspectionDataLoading(false);
      } else {
        setCurrentExecutionId(executionId);
      }
    };

    loadData();
    return () => {
      closeAndClear();
      resetVoice();
      setCurrentExecutionId(undefined);
    };
  }, [executionId]);

  return inspectionDataLoading || !minWaitDone;
}

export function useDynamicContainers(executionId: string) {
  const { containerTemplatesMap, createDynamicContainer, setDCTitleTypeId } = useContainerStore([
    'containerTemplatesMap',
    'createDynamicContainer',
    'setDCTitleTypeId',
  ]);
  const { t } = useTranslation();

  const containerTemplateIds = Object.keys(containerTemplatesMap);
  const isDynamicContainers = containerTemplateIds.length > 0;
  const hasMultipleDynamicContainers = containerTemplateIds.length > 1;

  const addContainer = async (containerTypeId: ContainerTypeId, title?: string) => {
    const isCreated = await createDynamicContainer(containerTypeId, executionId, title);
    if (!isCreated) toaster.error({ message: t('inspection.errors.dynamicContainerCreationFailure') });
  };

  const createNewDynamicContainer = (containerTypeId?: ContainerTypeId) => {
    const selectedTypeId = containerTypeId ?? containerTemplateIds[0];
    if (selectedTypeId) {
      if (config.dcCustomTitlesTypeIds?.includes(selectedTypeId)) {
        setDCTitleTypeId(selectedTypeId);
      } else {
        addContainer(selectedTypeId);
      }
    } else {
      toaster.error({ message: t('inspection.errors.dynamicContainerCreationFailure') });
    }
  };

  return {
    isDynamicContainers,
    hasMultipleDynamicContainers,
    createNewDynamicContainer,
    addContainer,
  };
}

/**
 * Calculate amount of containers with at least one event that is:
 * - applicable
 * - not reported
 * Divide by whether they have default values.
 */
export function useUnreportedEvents() {
  const { reports, validity } = useReportStore(['reports', 'validity']);
  const { containers } = useContainerStore(['containers']);
  const { visibilityBindings } = useUiEventStore(['visibilityBindings']);
  const getContainerStaticEvents = useGetContainerStaticEvents();
  const getUiEvent = useGetUiEvent();
  const getBridges = useGetCEBridges();

  const isApplicable = (container: Container) => {
    const { applicabilityEventId } = getContainerStaticEvents(container.id);
    if (!applicabilityEventId) return true;
    const applicabilityReport = pullLastReport(reports, container.id, applicabilityEventId);
    if (!applicabilityReport) return true;
    return applicabilityReport.reportedValue === ApplicabilityReportValue.APPLICABLE;
  };

  const isRequirementFilled = (bridge: AnyBridge, container: Container) => {
    if (!bridge.isMandatory) return true;
    return isEventReported(container.id, getUiEvent(bridge.uiEventId), reports);
  };

  const isChildTriggered = (bridge: AnyBridge, container: Container) => {
    const visibilityBinding = visibilityBindings[bridge.visibilityBindingIds[0]];
    if (!visibilityBinding) return true;
    const { triggerBridgeId } = visibilityBinding;
    // If container is dynamic, trigger is in the same container
    const [triggerContainerId, triggerEventId] = container.isDynamic
      ? [container.id, splitCEBridgeId(triggerBridgeId)[1]]
      : splitCEBridgeId(triggerBridgeId);

    const reportKey = getReportCollectionKey(triggerContainerId, triggerEventId);
    const [triggerValue] = getLastReportedValues(triggerContainerId, getUiEvent(triggerEventId), reports);
    const triggerIsValid = validity[reportKey];
    return isEventVisibleByBinding(visibilityBinding, triggerValue, triggerIsValid);
  };

  const canFillDefaultValue = (ceBridge: AnyBridge, container: Container) => {
    const uiEvent = getUiEvent(ceBridge.uiEventId);
    if (!exists(uiEvent.defaultValue)) return false;
    const containerId = container.isDynamic ? container.id : ceBridge.containerId;
    return !isEventReported(containerId, uiEvent, reports);
  };

  return useMemo(() => {
    const unreportedWithDefault = new Map<ReportCollectionKey, UiEvent>();
    const unreportedMandatory = new Map<ReportCollectionKey, UiEvent>();

    Object.values(containers).forEach((container) => {
      const applicable = isApplicable(container);
      if (!applicable) return;
      const bridges = getBridges(container.id, true);

      bridges.forEach((bridge) => {
        if (bridge.isChild && !isChildTriggered(bridge, container)) return;
        const uiEvent = getUiEvent(bridge.uiEventId);
        const key = getReportCollectionKey(container.id, bridge.uiEventId);
        if (canFillDefaultValue(bridge, container)) unreportedWithDefault.set(key, uiEvent);
        if (!isRequirementFilled(bridge, container)) unreportedMandatory.set(key, uiEvent);
      });
    });

    return { unreportedMandatory, unreportedWithDefault };
  }, [containers, reports, validity, visibilityBindings, getUiEvent, getBridges]);
}

export function useOutOfBoundsEvents() {
  const { boundedness } = useReportStore(['boundedness']);
  const { setFilters } = useFilterStore(['setFilters']);

  return {
    hasOutOfBoundsItems: Object.values(boundedness).includes(false),
    showOutOfBoundsContainers: () => setFilters({ outOfBounds: true }),
  };
}

export function useExecutionExists(executionId?: ExecutionId) {
  const online = useOnline();
  const { clear, start } = useTimeout(pollExecution, 1000);
  const { currentExecution, setCurrentExecution } = useFlowStore(['currentExecution', 'setCurrentExecution']);
  async function pollExecution([isOnline, id]: [boolean, string]) {
    const retry = () => start(true, id);
    if (isOnline) {
      const execution = await flowsApi.getExecutionById(id);
      if (execution) setCurrentExecution(execution);
      else retry();
    } else retry();
  }

  useEffect(() => {
    if (executionId && !currentExecution) {
      start(online, executionId);
    }
    return () => {
      setCurrentExecution(undefined);
      clear();
    };
  }, [online, executionId]);
}

interface BlockingModalProps {
  Icon: FC<IconProps>;
  name?: string;
  user?: UserIdentity;
  title: string;
  text: string;
  warning?: boolean;
}

export function useBlockingModalProps(execution?: Execution): BlockingModalProps | null {
  const { t } = useTranslation();
  const { userId } = useCurrentUser()!;
  if (!execution) return null;
  const { status, reviewedBy, finishedBy } = execution;

  const reviewerName = `${reviewedBy?.givenName} ${reviewedBy?.familyName}`;
  const finisherName = `${finishedBy?.givenName} ${finishedBy?.familyName}`;

  switch (status) {
    case ExecutionStatus.cancelled: {
      const isCancelledBySomeoneElse = finishedBy?.userId !== userId;
      if (isCancelledBySomeoneElse)
        return {
          Icon: IconCircleX,
          name: finisherName,
          user: finishedBy,
          title: t('inspection.executionBlockedModal.canceled.title'),
          text: t('inspection.executionBlockedModal.canceled.text', { name: finisherName }),
          warning: true,
        };
      return null;
    }
    case ExecutionStatus.done: {
      const isFinishedBySomeoneElse = finishedBy?.userId !== userId;
      if (isFinishedBySomeoneElse)
        return {
          Icon: IconFileCheck,
          name: finisherName,
          user: finishedBy,
          title: t('inspection.executionBlockedModal.completed.title'),
          text: t('inspection.executionBlockedModal.completed.text', { name: finisherName }),
        };
      return null;
    }
    case ExecutionStatus.inReview: {
      const isReviewedBySomeoneElse = reviewedBy?.userId !== userId;
      if (isReviewedBySomeoneElse)
        return {
          Icon: IconDeviceImacSearch,
          name: reviewerName,
          user: reviewedBy,
          title: t('inspection.executionBlockedModal.review.title'),
          text: t('inspection.executionBlockedModal.review.text', { name: reviewerName }),
        };
      return null;
    }
    case ExecutionStatus.expired:
      return {
        Icon: IconClockX,
        title: t('inspection.executionBlockedModal.expired.title'),
        text: t('inspection.executionBlockedModal.expired.text'),
        warning: true,
      };
    default:
      return null;
  }
}

const blockingModalId = 'execution-blocked-modal';
export function useExecutionBlockedModal(execution?: Execution) {
  const modalProps = useBlockingModalProps(execution);
  const navigate = useForceNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (modalProps) {
      const { Icon, name, user, title, text, warning } = modalProps;

      modalManager.info({
        id: blockingModalId,
        icon: Icon,
        closeOnClickOutside: false,
        withCloseButton: false,
        iconColor: warning ? 'red.5' : undefined,
        title,
        onConfirm: () => navigate(ROUTES.HOME),
        labels: { confirm: t('inspection.executionBlockedModal.exit') },
        message: (
          <Flex direction='column' align='center' gap='sm'>
            {name && (
              <Flex align='center' gap='xs'>
                <UserAvatar user={user} />
                <Text size='xl' data-testid='blocked-modal-user'>
                  {name}
                </Text>
              </Flex>
            )}
            <Text size='md' ta='center'>
              {text}
            </Text>
          </Flex>
        ),
      });
    }

    return () => modalManager.close(blockingModalId);
  }, [modalProps]);
}
