import { List } from '@phosphor-icons/react';
import { MultiSelectElementData, ReportValueType } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { aggregateMultiSelectReports } from 'stores/uiEvent';
import { EventBase, eventBaseTestIds } from '../../../EventBase';
import { MultiSelectEventProps } from '../../types';
import { MultiSelectEventDrawer, testIds as multiSelectEventDrawerTestIds } from './MultiSelectEventDrawer';
import { formatOptions, formatValues } from './MultiSelectEvent.utils';

export const testIds = {
  getEventTestId: (id: string) => `multiselect-event-${id}`,
  base: eventBaseTestIds,
  drawer: multiSelectEventDrawerTestIds,
};

export const MultiSelectEvent = ({
  reportKey,
  uiEvent,
  valid,
  reports,
  triggerReport,
  opened,
  onCloseDrawer,
  onOpenDrawer,
}: MultiSelectEventProps) => {
  const elementData = uiEvent.elementData as MultiSelectElementData;
  const values = useMemo(() => aggregateMultiSelectReports(reports[reportKey]), [reports]);
  const options = useMemo(() => formatOptions(elementData), [elementData]);
  const formattedValues = useMemo(() => formatValues(values, elementData), [values]);

  const reportEvent = (updatedValues: string[]) => {
    const add = updatedValues.filter((value) => !values.includes(value));
    const remove = values.filter((value) => !updatedValues.includes(value));
    const reportedValue = JSON.stringify({ add, remove });

    triggerReport({
      reportedValue,
      reportedValueType: ReportValueType.STRING,
    });
  };

  return (
    <>
      <EventBase
        key={reportKey}
        child={uiEvent.isChild}
        title={uiEvent.title}
        mandatory={uiEvent.isMandatory}
        Icon={List}
        value={formattedValues}
        valid={valid}
        withVoice={uiEvent.tapToSpeakEnabled}
        onClick={onOpenDrawer}
        data-testid={testIds.getEventTestId(reportKey)}
      />
      <MultiSelectEventDrawer
        opened={opened}
        title={uiEvent.title}
        options={options}
        valid={valid}
        selectedValues={values}
        onDrawerClosed={onCloseDrawer}
        onValuesPicked={reportEvent}
      />
    </>
  );
};
