import { rem, TextInput } from '@mantine/core';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, drawerTestIds } from 'components';
import { EventValidationIcon, eventValidationIconTestIds } from 'pages/InspectionPage/components/EventBase';

export const testIds = {
  listDrawerHeader: 'list-drawer-header',
  listDrawerClearSearchButton: 'list-drawer-clear-search-button',
  listDrawerSearchBar: 'list-drawer-search-bar',
  drawerTestIds,
  eventValidationIconTestIds,
};

interface ListDrawerProps {
  children: ReactNode;
  opened: boolean;
  title: string;
  valid?: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onDrawerClosed: () => void;
}

export const ListDrawer = ({
  children,
  opened,
  title,
  valid,
  searchValue,
  setSearchValue,
  onDrawerClosed,
}: ListDrawerProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!opened) {
      setSearchValue('');
    }
  }, [opened]);

  const handleDrawerClose = () => {
    setSearchValue('');
    onDrawerClosed();
  };

  return (
    <Drawer height='100%' opened={opened} onClose={handleDrawerClose} trapFocus={false}>
      <Drawer.Header
        withCloseButton
        leftSection={<EventValidationIcon valid={valid} size={24} h='100%' py='auto' />}
        data-testid={testIds.listDrawerHeader}
      >
        {title}
      </Drawer.Header>
      <TextInput
        styles={{
          input: {
            borderRadius: '50em',
            fontSize: rem(18),
            minHeight: rem(48),
          },
          section: {
            margin: '0 2px',
          },
        }}
        mx={14}
        variant='filled'
        value={searchValue}
        placeholder={t('common.search')}
        leftSection={<MagnifyingGlass />}
        rightSection={
          searchValue && (
            <X size={18} onClick={() => setSearchValue('')} data-testid={testIds.listDrawerClearSearchButton} />
          )
        }
        onChange={(event) => setSearchValue(event.target.value)}
        data-testid={testIds.listDrawerSearchBar}
      />
      <Drawer.Body pb={80}>{children}</Drawer.Body>
    </Drawer>
  );
};
