import { generateId } from '@aiola/frontend';
import { useDebouncedValue, useDocumentVisibility, useIsFirstRender, useListState } from '@mantine/hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDistracted } from 'stores/app';
import { useAuthStore, useCurrentUser } from 'stores/auth';
import { useContainerStore } from 'stores/container';
import { useFlowByExecutionId, useFlowStore } from 'stores/flow';
import { useVoiceMode } from 'stores/settings';
import { useOpenedEvent, useUIEventById } from 'stores/uiEvent';
import { useVoiceStore, VoiceStatus } from 'stores/voice';

export function useToolbarCapabilities() {
  const currentUser = useCurrentUser();
  const { openedEvent } = useOpenedEvent();
  const { containerTemplatesMap } = useContainerStore(['containerTemplatesMap']);
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const flow = useFlowByExecutionId(currentExecutionId!);
  const { isVoiceFirst } = useVoiceMode(currentUser?.userId ?? '', flow?.id!);

  const isDynamicContainersAvailable = () =>
    Object.values(containerTemplatesMap).map((ct) => Object.values(ct)[0]).length > 0;

  const isInsideEvent = !!openedEvent;
  const isVoiceEnabled = !!flow?.voiceInputEnabled;

  return useMemo(
    () => ({
      complete: !isInsideEvent,
      done: isInsideEvent,
      clear: isInsideEvent,
      dynamicContainers: !isInsideEvent && isDynamicContainersAvailable(),
      voice: isVoiceEnabled && (isVoiceFirst || isInsideEvent), // free speech - always, tap-and-speak - only inside event
    }),
    [containerTemplatesMap, isInsideEvent, isVoiceEnabled, isVoiceFirst],
  );
}

export function useVoiceFirstAvailability() {
  const currentUser = useCurrentUser();
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const flow = useFlowByExecutionId(currentExecutionId!);
  const { isVoiceFirst } = useVoiceMode(currentUser?.userId ?? '', flow?.id!);

  return isVoiceFirst && !!flow?.voiceInputEnabled;
}

export function useDynamicTemplates() {
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const { containerTemplatesMap, createDynamicContainer } = useContainerStore([
    'containerTemplatesMap',
    'createDynamicContainer',
  ]);

  const templates = useMemo(
    () =>
      Object.values(containerTemplatesMap).map((ct) => {
        const firstTemplate = Object.values(ct)[0];
        return { id: firstTemplate.typeId, name: firstTemplate.typeTitle };
      }),
    [containerTemplatesMap],
  );

  const create = useCallback(
    (templateId: string, title?: string) => createDynamicContainer(templateId, currentExecutionId!, title),
    [createDynamicContainer, currentExecutionId],
  );

  return { templates, create };
}

export function usePause() {
  const { changeVoiceState } = useVoiceStore(['changeVoiceState']);
  const shouldPause = useDistracted(['app-menu', 'edit-event', 'image-capture', 'image-edit', 'review']);

  const [debouncedShouldPause] = useDebouncedValue(shouldPause, 200);

  useEffect(() => {
    if (debouncedShouldPause) changeVoiceState('pause');
    else changeVoiceState('resume');
  }, [debouncedShouldPause]);
}

export function useVoiceActions() {
  const {
    changeVoiceState,
    updateStreamMetadata,
    currentVoiceStatus,
    internal: { setContext },
  } = useVoiceStore(['changeVoiceState', 'updateStreamMetadata', 'currentVoiceStatus', 'internal']);
  const { sessionId } = useAuthStore(['sessionId']);
  const { openedEvent } = useOpenedEvent();
  const uiEvent = useUIEventById(openedEvent?.eventId);
  const visibility = useDocumentVisibility();
  const isFirstRender = useIsFirstRender();
  const outsideVoiceState = useRef(currentVoiceStatus);

  useEffect(() => {
    updateStreamMetadata({ flowSessionId: sessionId! });
  }, [sessionId]);

  useEffect(() => {
    if (!isFirstRender) changeVoiceState('close');
  }, [visibility]);

  // when entering event, activate recording if event prefers voice and recording is inactive
  // when exiting event, deactivate recording if it was inactive when entering the event
  useEffect(() => {
    if (openedEvent) {
      outsideVoiceState.current = currentVoiceStatus;
      const shouldStart = uiEvent?.tapToSpeakEnabled && currentVoiceStatus === 'closed';
      if (shouldStart) changeVoiceState('open');
    } else {
      const shouldStop = outsideVoiceState.current === 'closed' && currentVoiceStatus === 'listening';
      if (shouldStop) changeVoiceState('close');
    }
  }, [openedEvent]);

  useEffect(
    () => () => {
      changeVoiceState('close');
      setContext();
    },
    [],
  );

  /** Change the voice state based on its current state */
  const autoChangeState = useCallback(() => {
    // always change voice status with the same context - changing the context is the event responsibility
    switch (currentVoiceStatus) {
      case 'closed':
        changeVoiceState('open');
        break;
      case 'paused':
        changeVoiceState('resume');
        break;
      default: // remaining case is 'listening'
        changeVoiceState('close');
    }
  }, [currentVoiceStatus]);

  return { state: currentVoiceStatus, autoChangeState };
}

interface Transcript {
  id: string;
  timestamp: number;
  transcript: string;
  status: VoiceStatus;
}

export function useTranscripts() {
  const [transcripts, { append, setItemProp }] = useListState<Transcript>([]);
  const { transcript, currentVoiceStatus } = useVoiceStore(['transcript', 'currentVoiceStatus']);
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (currentVoiceStatus === 'listening' || !isFirstRender)
      append({ id: generateId(4), transcript: '', timestamp: Date.now(), status: currentVoiceStatus });
  }, [currentVoiceStatus]);

  useEffect(() => {
    if (transcripts.length && transcript) setItemProp(transcripts.length - 1, 'transcript', transcript);
  }, [transcript]);

  return transcripts;
}
