import { ElementProps, Stack, StackProps } from '@mantine/core';

interface DrawerFooterProps extends StackProps, ElementProps<'div', keyof StackProps> {}

export const testIds = {
  container: 'drawer-footer-container',
};

export const DrawerFooter = ({ children, ...props }: DrawerFooterProps) => (
  <Stack component='footer' p='md' data-testid={testIds.container} {...props}>
    {children}
  </Stack>
);
