import { Drawer, EmptyState } from 'components';
import { ComponentProps, Fragment } from 'react';
import { ComboboxItem, Radio, UnstyledButton, Text, Divider } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useSearchFilter } from '../../hooks/useSearchFilter';
import { ListDrawer, testIds as listDrawerTestIds } from '../ListDrawer/ListDrawer';

export const testIds = {
  getDropdownDrawerItemValue: (value: string) => `dropdown-event-drawer-item-${value}`,
  listDrawerTestIds,
  emptyState: 'dropdown-event-drawer-empty-state',
};

interface DropdownEventDrawerProps extends Pick<ComponentProps<typeof Drawer>, 'opened'> {
  title: string;
  value?: string;
  options: ComboboxItem[];
  valid?: boolean;
  onDrawerClosed: () => void;
  onValuePicked: (finalValue?: string) => void;
}

export const DropdownEventDrawer = ({
  opened,
  title,
  value,
  options,
  valid,
  onValuePicked,
  onDrawerClosed,
}: DropdownEventDrawerProps) => {
  const { t } = useTranslation();
  const { searchValue, setSearchValue, filteredOptions } = useSearchFilter(options);

  const handleSelect = (selectedValue: string) => {
    onValuePicked(selectedValue);
  };

  return (
    <ListDrawer
      opened={opened}
      title={title}
      valid={valid}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      onDrawerClosed={onDrawerClosed}
    >
      <EmptyState
        mt='xl'
        hidden={!!filteredOptions.length}
        icon={<MagnifyingGlass weight='duotone' size={48} />}
        title={t('common.noSearchResults')}
        data-testid={testIds.emptyState}
      />
      <Radio.Group value={value}>
        {filteredOptions.map(({ value: optionValue, label }, index) => (
          <Fragment key={optionValue}>
            <UnstyledButton
              onClick={() => handleSelect(optionValue)}
              data-testid={testIds.getDropdownDrawerItemValue(optionValue)}
              className='flex flex-row-reverse gap-4 items-center justify-between w-full px-1 py-6'
            >
              <Radio value={optionValue} size='md' />
              <Text pl='sm' fz='lg'>
                {label}
              </Text>
            </UnstyledButton>
            {index < options.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Radio.Group>
    </ListDrawer>
  );
};
