import { ContainerId, ReportedValue } from '@flow/flow-backend-types';
import { useBoundsByEventId, useEventVisibility, useOpenedEvent, useValidationByEventId } from 'stores/uiEvent';
import { useMemo } from 'react';
import { getReportCollectionKey, useReporter, useReportStore } from 'stores/report';
import { names, useSpy } from 'services/espionage';
import { useDistraction } from 'stores/app';
import { useVoiceStore } from 'stores/voice';
import { useFlowStore } from 'stores/flow';
import { BridgedUiEvent } from './EventItem.types';
import { AggregatedEventProps, BaseEventProps, DrawerEventProps, ValidationProps } from './types';
import {
  appendEventTestIds,
  AppendTextEvent,
  ButtonsEvent,
  buttonsEventTestIds,
  DateEvent,
  dateEventTestIds,
  DropdownEvent,
  dropdownEventTestIds,
  MultiSelectEvent,
  multiselectEventTestIds,
  NumericEvent,
  NumericEventTestIds,
  TextEvent,
  textEventTestIds,
  TimeEvent,
  timeEventTestIds,
} from './components';

interface EventItemProps {
  containerId: ContainerId;
  uiEvent: BridgedUiEvent;
}

export const testIds = {
  types: {
    text: textEventTestIds,
    append: appendEventTestIds,
    numeric: NumericEventTestIds,
    dropdown: dropdownEventTestIds,
    multiselect: multiselectEventTestIds,
    buttons: buttonsEventTestIds,
    time: timeEventTestIds,
    date: dateEventTestIds,
  },
};

export const EventItem = ({ containerId, uiEvent }: EventItemProps) => {
  const { spyClick } = useSpy();
  const { id: eventId } = uiEvent;
  const { distract, concentrate } = useDistraction('edit-event');
  const { setOpenedEvent, openedEvent } = useOpenedEvent();

  const visible = useEventVisibility(eventId, containerId);
  const reportKey = getReportCollectionKey(containerId, eventId);

  const { currentExecutionId: executionId = '' } = useFlowStore(['currentExecutionId']);
  const { lastReport, triggerReport } = useReporter({ executionId, containerId, eventId });
  const { reports, validity, boundedness } = useReportStore(['reports', 'validity', 'boundedness']);

  const validation = useValidationByEventId(containerId, eventId);
  const bounds = useBoundsByEventId(containerId, eventId);

  const {
    internal: { setContext: setVoiceContext },
  } = useVoiceStore(['internal', 'reportingContext']);

  const isCurrentContext = openedEvent?.containerId === containerId && openedEvent?.eventId === eventId;

  const onStartEditing = () => {
    spyClick(names.Container.ManualEditEvent, { containerId, eventId });
    distract();
  };

  const onFinishEditing = (value: ReportedValue) => {
    spyClick(names.Container.ManualEditEventDone, { containerId, eventId, value });
    concentrate();
  };

  const onOpenDrawer = () => {
    setOpenedEvent({ containerId, eventId });
    // Temporary until context reporting is re-implemented
    if (uiEvent.type === 'AppendTextEvent' || uiEvent.type === 'TextEvent')
      setVoiceContext({ containerId, eventDefinitionId: eventId });
  };

  const onCloseDrawer = () => {
    setVoiceContext();
    setOpenedEvent(undefined);
  };

  const getBaseEventProps = (): BaseEventProps => ({
    uiEvent,
    reportKey,
    lastReportedEvent: lastReport,
    triggerReport,
  });

  const getValidationProps = (): ValidationProps => ({
    validation,
    bounds,
    valid: validity[reportKey],
    bounded: boundedness[reportKey],
  });

  const getDrawerEventProps = (): DrawerEventProps => ({
    opened: isCurrentContext,
    onStartEditing,
    onFinishEditing,
    onOpenDrawer,
    onCloseDrawer,
  });

  const getAggregatedEventProps = (): AggregatedEventProps => ({
    reports,
  });

  const getEventUiComponent = (event: BridgedUiEvent) => {
    if (!visible) return null;
    switch (event.type) {
      case 'ButtonsEvent':
        return <ButtonsEvent {...getBaseEventProps()} {...getValidationProps()} />;
      case 'DropdownEvent':
        return <DropdownEvent {...getBaseEventProps()} {...getValidationProps()} {...getDrawerEventProps()} />;
      case 'MultiSelectEvent':
        return (
          <MultiSelectEvent
            {...getBaseEventProps()}
            {...getValidationProps()}
            {...getDrawerEventProps()}
            {...getAggregatedEventProps()}
          />
        );
      case 'NumericEvent':
        return <NumericEvent {...getBaseEventProps()} {...getValidationProps()} {...getDrawerEventProps()} />;
      case 'DateEvent':
        return <DateEvent {...getBaseEventProps()} {...getValidationProps()} {...getDrawerEventProps()} />;
      case 'TimeOfDayEvent':
        return <TimeEvent {...getBaseEventProps()} {...getValidationProps()} {...getDrawerEventProps()} />;
      case 'TextEvent':
        return <TextEvent {...getBaseEventProps()} {...getDrawerEventProps()} />;
      case 'AppendTextEvent':
        return <AppendTextEvent {...getBaseEventProps()} {...getDrawerEventProps()} {...getAggregatedEventProps()} />;
      default:
        return null;
    }
  };

  return useMemo(() => getEventUiComponent(uiEvent), [reportKey, lastReport, openedEvent, visible]);
};
