import { Fragment, PropsWithChildren } from 'react';
import { Card, Divider, Flex, Popover, Text } from '@mantine/core';
import { UserIdentity } from '@flow/flow-backend-types';
import { ZIndex } from 'consts';
import { UserAvatar } from '../UserAvatar';

interface UserAvatarPopoverProps extends PropsWithChildren {
  users: UserIdentity[];
  disabled?: boolean;
  onChange: () => void;
}

export const testIds = {
  popoverUserAvatar: (userId: string) => `popover-user-avatar-${userId}`,
};

export const UserAvatarPopover = ({ children, disabled, users, onChange }: UserAvatarPopoverProps) => (
  <Popover disabled={disabled} position='bottom-end' shadow='md' zIndex={ZIndex.Modals} onChange={onChange}>
    <Popover.Dropdown className='border-0 shadow-none' p={0} mt={2} bg='none'>
      <Card className='gap-y-2' bg='cool.7' p='xs' radius='md'>
        {users.map((user, index) => (
          <Fragment key={user?.userId}>
            <Flex className='gap-x-2' align='center' w={218}>
              <UserAvatar
                className='border border-white border-solid'
                size='sm'
                user={user!}
                data-testid={testIds.popoverUserAvatar(user?.userId!)}
              />
              <Text size='sm' fw={500} c='white'>
                {user?.givenName} {user?.familyName}
              </Text>
            </Flex>
            {index === 0 && users.length > 1 && <Divider w='100%' color='gray.7' />}
          </Fragment>
        ))}
      </Card>
    </Popover.Dropdown>
    <Popover.Target>{children}</Popover.Target>
  </Popover>
);
