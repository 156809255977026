import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { db } from 'services/db';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook, exists } from '@aiola/frontend';
import {
  ExecutionId,
  FlowId,
  FlowMetadata,
  FlowMetadataField,
  GetFlowMetadataResponse,
} from '@flow/flow-backend-types';
import { networkStore } from 'stores/network';
import { inspectionMetaApi } from './inspectionMeta.api';
import { MetaValues } from './inspectionMeta.types';

interface InspectionMetaActions {
  loadInspectionMeta: (flowId: string, version: string) => Promise<GetFlowMetadataResponse | undefined>;
  getUniqueIdentifierField: (flowId: string, version: string) => Promise<FlowMetadataField | undefined>;
  updateCache: (flowId: string, version: string) => Promise<GetFlowMetadataResponse | undefined>;
  reset: () => void;
}

interface InspectionMetaState {
  flowsMetaMap: Record<FlowId, FlowMetadata>;
  executionPreFieldValuesMap: Record<ExecutionId, MetaValues>;
  executionPostFieldValuesMap: Record<ExecutionId, MetaValues>;
}

const devtoolsOptions: DevtoolsOptions = {
  name: 'inspectionMeta',
  store: 'inspectionMeta',
  enabled: process.env.NODE_ENV === 'development',
};

const initialState: InspectionMetaState = {
  flowsMetaMap: {},
  executionPreFieldValuesMap: {},
  executionPostFieldValuesMap: {},
};

export const inspectionMetaStore = create(
  devtools(
    immer<InspectionMetaState & InspectionMetaActions>((set, get) => ({
      ...initialState,
      loadInspectionMeta: async (flowId: string, version: string) => {
        console.info(`🔎 Looking for metadata for flow [${flowId}] with version [${version}]`);
        if (networkStore.getState().online) await get().updateCache(flowId, version);
        const inspectionMeta = await db.flowMetadata.get({ id: flowId, version });
        const metaIsValid = exists(inspectionMeta) && !!inspectionMeta.preInspectionFields;
        if (metaIsValid) {
          set((state) => {
            state.flowsMetaMap[flowId] = inspectionMeta;
          });
          console.info(
            `🔎 Found ${inspectionMeta.preInspectionFields?.length} metadata fields for flow [${flowId}] with version [${version}]`,
          );
        } else console.info(`🕵️ No metadata found for flow [${flowId}] with version [${version}]`);
        return metaIsValid ? inspectionMeta : undefined;
      },
      getUniqueIdentifierField: async (flowId: string, version: string) => {
        const flowMetadata = await db.flowMetadata.get({ id: flowId, version });
        return flowMetadata?.preInspectionFields?.find((field) => field.id === flowMetadata.uniqueIdentifierFieldId);
      },
      updateCache: async (flowId: string, version: string) => {
        const inspectionMeta = await inspectionMetaApi.fetchInspectionMetaByFlowId(flowId, version);
        if (inspectionMeta) {
          await db.flowMetadata.put(inspectionMeta);
        }
        return inspectionMeta;
      },
      reset: () => {
        set(initialState);
      },
    })),
    devtoolsOptions,
  ),
);

export const useInspectionMetaStore = createStoreHook<InspectionMetaState & InspectionMetaActions>({
  store: inspectionMetaStore,
  useShallow,
});
