import { ContainerId, UiEventId } from '@flow/flow-backend-types';
import { Box, Paper, Text } from '@mantine/core';
import { Debug } from 'components';
import { useBridgedUiEvents } from './EventList.hooks';
import { EventItem } from '../EventItem/EventItem';
import classes from './EventList.module.css';

interface EventListProps {
  containerId: ContainerId;
}

export const testIds = {
  getWrapperTestId: (containerId: ContainerId) => `event-list-${containerId}`,
  getParentListTestId: (parentId: UiEventId) => `event-list-parent-${parentId}`,
};

export const EventList = ({ containerId }: EventListProps) => {
  const uiEventGroups = useBridgedUiEvents(containerId);

  if (!uiEventGroups.length) return null;

  return (
    <Box className={classes.root} data-testid={testIds.getWrapperTestId(containerId)}>
      {uiEventGroups.map((uiEventGroup) => {
        const { id: parentId } = uiEventGroup[0];
        return (
          <Paper key={parentId} className={classes.list} withBorder data-testid={testIds.getParentListTestId(parentId)}>
            <Debug>
              <Text size='xs' c='amber'>
                List for parent {parentId}
              </Text>
            </Debug>
            {uiEventGroup.map((uiEvent) => (
              <EventItem key={uiEvent.id} containerId={containerId} uiEvent={uiEvent} />
            ))}
          </Paper>
        );
      })}
    </Box>
  );
};
