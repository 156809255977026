import { Container } from '@flow/flow-backend-types';
import i18n from 'services/i18n';
import { ContainerProgressionStatus } from 'stores/report';
import { Highlight, Text, UnstyledButton } from '@mantine/core';
import { CaretDown, CaretRight } from '@phosphor-icons/react';
import { names, useSpy } from 'services/espionage';
import classes from './ContainerItemHeader.module.css';

interface ContainerItemHeaderProps {
  container: Container;
  opened: boolean;
  progression: ContainerProgressionStatus;
  search?: string;
  onClick: () => void;
}

const progressionTitles = {
  [ContainerProgressionStatus.NOT_STARTED]: null,
  [ContainerProgressionStatus.IN_PROGRESS]: i18n.t('inspection.container.progression.status.inProgress'),
  [ContainerProgressionStatus.DONE]: i18n.t('inspection.container.progression.status.done'),
  [ContainerProgressionStatus.ERROR]: i18n.t('inspection.container.progression.status.error'),
};

export const testIds = {
  button: 'container-item-header-button',
  title: 'container-item-header-title',
  progression: 'container-item-header-progression',
};

export const ContainerItemHeader = ({
  container,
  opened,
  progression,
  search = '',
  onClick,
}: ContainerItemHeaderProps) => {
  const { spyClick } = useSpy();

  const onClickHeader = () => {
    if (opened) spyClick(names.Container.Close, container.id);
    else spyClick(names.Container.Open, container.id);
    onClick();
  };

  return (
    <UnstyledButton
      className={classes.header}
      mb={opened ? 'md' : undefined}
      onClick={onClickHeader}
      data-testid={testIds.button}
    >
      <Text className={classes.progression} data-testid={testIds.progression}>
        {progressionTitles[progression]}
      </Text>
      <Highlight
        highlight={search}
        size='md'
        lineClamp={opened ? undefined : 2}
        className={classes.title}
        data-testid={testIds.title}
      >
        {container.title}
      </Highlight>
      {container?.description && <Text className={classes.description}>{container.description}</Text>}
      {opened ? (
        <CaretDown className={classes.chevron} size={16} />
      ) : (
        <CaretRight className={classes.chevron} size={16} />
      )}
    </UnstyledButton>
  );
};
