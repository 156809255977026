import { ComponentProps, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Box, Button, Center, Group, ThemeIcon } from '@mantine/core';
import { ArrowLeft, FileArrowDown } from '@phosphor-icons/react';
import { useViewportSize } from '@mantine/hooks';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { downloadPdfFile } from 'utils';
import { Drawer } from 'components';
import { names, useSpy, useSpyMount } from 'services/espionage';
import { LogoLoopSingle } from 'assets';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { ZIndex } from 'consts';

interface PdfPreviewDrawerProps {
  url: string;
  filename?: string;
  onBack: () => void;
}

const PAGE_MARGIN = 16;

export const testIds = {
  wrapper: 'pdf-viewer-wrapper',
  backBtn: 'pdf-viewer-back',
  downloadBtn: 'pdf-viewer-download',
};

export const PdfPreviewDrawer = ({ url, filename = 'Unknown', onBack }: PdfPreviewDrawerProps) => {
  const { t } = useTranslation();
  const { spyRef, spyClick } = useSpy();
  const [pdfPages, setPdfPages] = useState(0);
  const { height, width } = useViewportSize();
  const devicePixelRatio = Math.min(2, window.devicePixelRatio);

  useSpyMount(spyRef, names.PdfPreviewDrawer.self);

  const onBackClick = () => {
    spyClick(names.PdfPreviewDrawer.Back);
    onBack();
  };

  const onDownload = () => {
    spyClick(names.PdfPreviewDrawer.Download);
    downloadPdfFile(url, filename);
  };

  const onDocumentLoadSuccess: ComponentProps<typeof Document>['onLoadSuccess'] = (pdf) => {
    setPdfPages(pdf.numPages);
  };

  return (
    <Drawer opened={!!url} height='100%' variant='grayed' zIndex={ZIndex.PDF} onClose={onBack}>
      <Box data-testid={testIds.wrapper}>
        <Group justify='space-between' className='sticky shadow-sm z-10' left={0} top={0} py='xs' bg='white'>
          <Button
            variant='transparent'
            size='sm'
            h='30px'
            color='cool.7'
            fz='md'
            leftSection={<ArrowLeft weight='bold' />}
            onClick={onBackClick}
            data-testid={testIds.backBtn}
          >
            {t('navbar.back')}
          </Button>
          <Button
            hidden={isIOS}
            variant='transparent'
            size='sm'
            h='30px'
            color='blue.6'
            fz='md'
            rightSection={<FileArrowDown weight='bold' />}
            onClick={onDownload}
            data-testid={testIds.downloadBtn}
          >
            {t('common.download')}
          </Button>
        </Group>
        <Box py='md'>
          <Document
            file={url}
            loading={
              <Center>
                <ThemeIcon size={70} variant='transparent' c='emerald.9'>
                  <LogoLoopSingle className='animate-spin' width={70} height='auto' />
                </ThemeIcon>
              </Center>
            }
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {pdfPages &&
              Array.from({ length: pdfPages }, (_, index) => (
                <Box key={`page${index + 1}`} px={PAGE_MARGIN} py={PAGE_MARGIN / 2}>
                  <Page
                    pageNumber={index + 1}
                    width={width - PAGE_MARGIN * 2}
                    height={height - PAGE_MARGIN}
                    devicePixelRatio={devicePixelRatio}
                    loading={<div />}
                  />
                </Box>
              ))}
          </Document>
        </Box>
      </Box>
    </Drawer>
  );
};
