import { NotificationData, notifications } from '@mantine/notifications';
import { Text } from '@mantine/core';
import { Check, ExclamationMark } from '@phosphor-icons/react';
import { Bread, Toast } from './toaster.types';

export const testIds = {
  toast: 'toast',
  error: {
    title: 'toast-error-title',
    icon: 'toast-error-icon',
  },
  success: {
    title: 'toast-success-title',
    icon: 'toast-success-icon',
  },
};

export class Toaster {
  error(toast: Toast) {
    notifications.show({
      ...toast,
      color: 'red.9',
      icon: <ExclamationMark data-testid={testIds.error.icon} />,
      title: (
        <Text size='md' fw={600} c='red.9' data-testid={testIds.error.title}>
          {toast.title}
        </Text>
      ),
    });
  }

  success(toast: Toast) {
    notifications.show({
      ...toast,
      color: 'emerald.5',
      icon: <Check data-testid={testIds.success.icon} />,
      title: (
        <Text size='md' fw={600} c='emerald.5' data-testid={testIds.success.title}>
          {toast.title}
        </Text>
      ),
    });
  }

  custom(toast: NotificationData) {
    notifications.show(toast);
  }

  info(_toast: Toast) {
    throw new Error('Method not implemented.');
  }

  warning(_toast: Toast) {
    throw new Error('Method not implemented.');
  }

  '🍞'(bread: Bread) {
    notifications.show({ ...bread });
  }

  reheat(toast: Toast & { id: string }) {
    notifications.update(toast);
  }

  dispose(id: string) {
    notifications.hide(id);
  }

  clear() {
    notifications.clean();
  }
}
