import { ElementProps, ThemeIcon, ThemeIconProps } from '@mantine/core';
import { CheckCircle, Warning, XCircle } from '@phosphor-icons/react';

interface EventValidationIconProps extends ThemeIconProps, ElementProps<'div', keyof ThemeIconProps> {
  valid?: boolean;
  bounded?: boolean;
}

export const testIds = {
  valid: 'event-validation-valid',
  invalid: 'event-validation-invalid',
  unbound: 'event-validation-unbound',
};

export const EventValidationIcon = ({ valid, bounded, size = 20, ...props }: EventValidationIconProps) => {
  if (bounded === false)
    return (
      <ThemeIcon size={size} variant='transparent' color='red.5' data-testid={testIds.unbound} {...props}>
        <Warning weight='fill' />
      </ThemeIcon>
    );
  if (valid === true)
    return (
      <ThemeIcon size={size} variant='transparent' color='emerald.5' data-testid={testIds.valid} {...props}>
        <CheckCircle weight='fill' />
      </ThemeIcon>
    );
  if (valid === false)
    return (
      <ThemeIcon size={size} variant='transparent' color='amber.5' data-testid={testIds.invalid} {...props}>
        <XCircle weight='fill' />
      </ThemeIcon>
    );
  return null;
};
