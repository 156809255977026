import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export function useGreeting() {
  const { t } = useTranslation();

  const hours = dayjs().hour();
  if (hours >= 4 && hours < 12) return t('greeting.morning');
  if (hours >= 12 && hours < 18) return t('greeting.afternoon');
  return t('greeting.evening');
}
