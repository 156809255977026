import { useTranslation } from 'react-i18next';
import { ActionIcon, Flex, Indicator, Text } from '@mantine/core';
import { SlidersHorizontal } from '@phosphor-icons/react';

interface HistoryFilterPanelProps {
  filteredCount: number;
  filtersApplied: boolean;
  onFiltersClick: () => void;
}

export const testIds = {
  wrapper: 'history-filters-panel',
  title: 'history-filters-title',
  filteredCount: 'history-filters-panel-count',
  openFiltersButton: 'history-filters-panel-button',
};

export const HistoryFilterPanel = ({ filteredCount, filtersApplied, onFiltersClick }: HistoryFilterPanelProps) => {
  const { t } = useTranslation();

  return (
    <Flex align='center' justify='space-between' py='tn' data-testid={testIds.wrapper}>
      <Flex align='baseline'>
        <Text fw={600} c='cool.5' data-testid={testIds.title}>
          {t('home.filters.panel.title')}
        </Text>
        <Text c='cool.4' pl='xs' data-testid={testIds.filteredCount}>
          {t('home.filters.panel.items', { count: filteredCount })}
        </Text>
      </Flex>
      <ActionIcon
        className='overflow-visible'
        size={36}
        radius='50%'
        bg={filtersApplied ? 'cool.2' : 'transparent'}
        c='emerald.9'
        onClick={onFiltersClick}
        data-testid={testIds.openFiltersButton}
      >
        <Indicator display='flex' inline size={10} offset={0} color='red.5' disabled={!filtersApplied}>
          <SlidersHorizontal weight='bold' />
        </Indicator>
      </ActionIcon>
    </Flex>
  );
};
