import isEqual from 'lodash.isequal';
import {
  ReportedEvent,
  GeneratedSource,
  PutReportedEventRequest,
  Validation,
  ContainerId,
  Container,
  CEBridge,
  UiEvent,
  UiEventId,
  CTBridge,
  ReportedValue,
  UiEventType,
} from '@flow/flow-backend-types';
import { ApplicabilityReportValue } from '@jargonic/event-definition-types';
import { generateId, TimeTurner } from '@aiola/frontend';
import { isEventValid, aggregateMultiSelectReports, AnyBridge, UIEventsMap } from 'stores/uiEvent';
import { aggregateImageData } from 'contexts/ItemPhotoContext/ItemPhotoContext.utils';
import {
  ContainerProgressionStatus,
  ReportCollectionKey,
  ReportDynamicData,
  ReportedEventsMap,
  ReportValidityMap,
} from './report.types';

export function getReportCollectionKey(containerId: string, eventId: string): ReportCollectionKey {
  return `${containerId}:${eventId}`;
}

export function splitReportCollectionKey(reportKey: ReportCollectionKey): [ContainerId, UiEventId] {
  if (!reportKey) return ['', ''];
  const split = reportKey.split(':');
  return [split[0], split[1] ?? ''];
}

export function isApplicableByReport(report?: ReportedEvent) {
  if (!report) return true;
  const { reportedValue } = report;
  return !reportedValue || Boolean(reportedValue === ApplicabilityReportValue.APPLICABLE);
}

export function isReportFirstAndEmpty(report: ReportDynamicData, reportCollection: ReportedEvent[]) {
  if (reportCollection.length === 0) {
    const { reportedValue } = report;
    return reportedValue === null;
  }
  return false;
}

export function isDuplicatedReport(report: ReportDynamicData, lastReport?: ReportedEvent) {
  if (!lastReport) return false;

  const { reportedValue, extraDetails = {} } = report;
  const { reportedValue: lastReportValue, extraDetails: lastReportExtraDetails = {} } = lastReport;

  if (reportedValue === lastReportValue) return isEqual(extraDetails, lastReportExtraDetails);

  return false;
}

export const sortReportsByCreationDate = (reports: PutReportedEventRequest[]) =>
  reports.sort((a, b) => a.creationDateClientTime - b.creationDateClientTime);

export const filterDuplicatesAndEnrichReports = (
  reports: ReportDynamicData[],
  currentReports: ReportedEventsMap,
  isOffline: boolean,
): PutReportedEventRequest[] =>
  reports
    .filter((report) => {
      const reportKey = getReportCollectionKey(report.containerId, report.eventDefId);
      const lastAddedReport = currentReports[reportKey]?.at(-1);
      const reportCollection = currentReports[reportKey] ?? [];
      const shouldOmitReport =
        isDuplicatedReport(report, lastAddedReport) || isReportFirstAndEmpty(report, reportCollection);
      return !shouldOmitReport;
    })
    .map((report) => ({
      ...report,
      id: generateId(),
      creationDateClientTime: Date.now(),
      generatedSource: GeneratedSource.UI,
      tz: TimeTurner.getLocalTimezone(),
      isOffline,
    }));

export const buildReportsMap = (reports: PutReportedEventRequest[]): ReportedEventsMap =>
  sortReportsByCreationDate(reports).reduce<ReportedEventsMap>((acc, report) => {
    const collectionKey = getReportCollectionKey(report.containerId, report.eventDefId);
    if (!acc[collectionKey]) acc[collectionKey] = [];
    acc[collectionKey].push(report as ReportedEvent);
    return acc;
  }, {});

export function pullLastReport(
  reports: ReportedEventsMap,
  containerId: string,
  eventId: string,
): ReportedEvent | undefined {
  const reportKey = getReportCollectionKey(containerId, eventId);
  return reports[reportKey]?.at(-1);
}

export function deriveEventValidity({
  ceBridge,
  uiEvent,
  reports,
  validations,
  containerId,
}: {
  ceBridge: CEBridge | CTBridge;
  uiEvent: UiEvent;
  reports: ReportedEventsMap;
  validations: Record<string, Validation>;
  containerId: ContainerId;
}): boolean | undefined {
  if (!ceBridge || !uiEvent) return undefined;
  const validation = validations[ceBridge.validationIds[0] ?? ''];
  return isEventValid(uiEvent, containerId, reports, validation);
}

export function deriveEventBoundedness({
  ceBridge,
  uiEvent,
  reports,
  validations,
  containerId,
}: {
  ceBridge: CEBridge | CTBridge;
  uiEvent: UiEvent;
  reports: ReportedEventsMap;
  validations: Record<string, Validation>;
  containerId: ContainerId;
}): boolean | undefined {
  if (!ceBridge || !uiEvent) return undefined;
  const validation = validations[ceBridge.boundIds[0] ?? ''];
  return isEventValid(uiEvent, containerId, reports, validation);
}

export function deriveEventLimitState({
  ceBridge,
  uiEvent,
  reports,
  validations,
  containerId,
}: {
  ceBridge: CEBridge | CTBridge;
  uiEvent: UiEvent;
  reports: ReportedEventsMap;
  validations: Record<string, Validation>;
  containerId: ContainerId;
}) {
  return {
    validity: deriveEventValidity({ ceBridge, uiEvent, reports, validations, containerId }),
    boundedness: deriveEventBoundedness({ ceBridge, uiEvent, reports, validations, containerId }),
  };
}

/**
 * Retrieves the last reported values for a given container and UI event.
 *
 * @returns An array of reported values. For `MultiSelectEvent`, it returns all selected values.
 *          For other events, it returns an array containing the last reported value, if any.
 *
 * @remarks
 * This function always returns an array to provide a consistent interface for handling reported values,
 * regardless of the event type. Even for events that typically have a single value, returning an array
 * simplifies the processing logic by allowing consumers of this function to handle the result uniformly,
 * without needing to check the event type or handle singular and plural cases separately.
 */
export function getLastReportedValues(
  containerId: ContainerId,
  uiEvent: UiEvent,
  reports: ReportedEventsMap,
): ReportedValue[] {
  if (uiEvent.type === 'MultiSelectEvent') {
    const reportKey = getReportCollectionKey(containerId, uiEvent.id);
    return aggregateMultiSelectReports(reports[reportKey] ?? []);
  }

  const reportedValue = pullLastReport(reports, containerId, uiEvent.id)?.reportedValue;
  return reportedValue ? [reportedValue] : [];
}

export function deriveParentProgression(childProgression: ContainerProgressionStatus[]): ContainerProgressionStatus {
  if (childProgression.includes(ContainerProgressionStatus.ERROR)) return ContainerProgressionStatus.ERROR;
  if (childProgression.every((p) => p === ContainerProgressionStatus.DONE)) return ContainerProgressionStatus.DONE;
  if (childProgression.every((p) => p === ContainerProgressionStatus.NOT_STARTED))
    return ContainerProgressionStatus.NOT_STARTED;
  return ContainerProgressionStatus.IN_PROGRESS;
}

export function deriveChildProgression({
  bridges,
  boundedness,
  container,
  reports,
  uiEvents,
}: {
  bridges: AnyBridge[];
  boundedness: ReportValidityMap;
  container: Container;
  reports: ReportedEventsMap;
  uiEvents: UIEventsMap;
}): ContainerProgressionStatus {
  const { id: containerId, isDynamic, containerTypeId } = container;
  const bridgeContainerId = isDynamic ? containerTypeId : containerId;

  let mandatoryDone = true;
  let hasReports = false;

  for (const bridge of bridges) {
    if (bridge.containerId !== bridgeContainerId) continue;

    const { uiEventId, isMandatory } = bridge;
    const { type } = uiEvents[uiEventId];
    const key = getReportCollectionKey(containerId, uiEventId);

    if (boundedness[key] === false) {
      return ContainerProgressionStatus.ERROR;
    }

    const hasValue = hasReportValue(type, reports[key]);
    hasReports ||= hasValue;

    if (isMandatory && !hasValue) {
      mandatoryDone = false;
    }
  }
  if (!hasReports) return ContainerProgressionStatus.NOT_STARTED;
  return mandatoryDone ? ContainerProgressionStatus.DONE : ContainerProgressionStatus.IN_PROGRESS;
}

export function hasReportValue(eventType: UiEventType, reportData: ReportedEvent[]): boolean {
  if (eventType === 'MultiSelectEvent') {
    return aggregateMultiSelectReports(reportData).length > 0;
  }
  if (eventType === 'ImageEvent') {
    return aggregateImageData(reportData ?? []).size > 0;
  }
  return Boolean(reportData?.at(-1)?.reportedValue);
}
