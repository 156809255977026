import { useCallback, useEffect } from 'react';
import { toaster } from 'services/toaster';
import { logger } from 'services/logger';
import { Button, Flex, Text } from '@mantine/core';
import { WarningOctagon } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

export function useHandleNewAppVersion() {
  const { t } = useTranslation();

  const onNewVersion = useCallback(async () => {
    toaster.custom({
      styles: {
        icon: { backgroundColor: 'transparent', color: 'red' },
      },
      color: 'red',
      autoClose: false,
      withBorder: true,
      withCloseButton: false,
      icon: <WarningOctagon />,
      message: (
        <Flex align='center' justify='space-between'>
          <Text size='sm' fw={600} c='red'>
            {t('common.newVersionText')}
          </Text>
          <Button variant='light' size='xs' color='red' onClick={() => window.location.reload()}>
            {t('common.update')}
          </Button>
        </Flex>
      ),
    });
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js').then((registration) => {
        // Listen for updates to the service worker
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
                logger.info('A new service worker has been installed but is waiting to take control.');
              }
            };
          }
        };
      });

      // Listen for when the new service worker takes control
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // Trigger the update notification when the new service worker is active
        logger.info('New service worker is active and waiting for manual update');
        onNewVersion();
      });
    }
  }, []);
}
