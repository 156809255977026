import { Box, Center, SegmentedControl } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { names, useSpy, HomePageTabOptions } from 'services/espionage';
import { FormsTabContent, HistoryTabContent } from 'pages/HomePage/components';
import { useSetState } from '@mantine/hooks';
import { HistoryFilterValues } from 'stores/flow';
import { HISTORY_FILTER_DEFAULT_VALUE } from 'consts';
import { AnimationStage, CONTENT_TRANSITION_DURATION, useHomePageLoad } from './HomePageLoadContext';
import classes from './SegmentedControl.module.css';

export const testIds = {
  tabs: 'home-page-tabs',
};

export const HomePageTabs = () => {
  const { spyClick } = useSpy();
  const { t } = useTranslation();
  const formsTab = t('home.tabs.forms');
  const historyTab = t('home.tabs.history');
  const { animationStage } = useHomePageLoad(AnimationStage.content);
  const [tab, setTab] = useState(formsTab);
  const [filterValues, setFilterValues] = useSetState<HistoryFilterValues>({
    statuses: [HISTORY_FILTER_DEFAULT_VALUE],
    periods: [HISTORY_FILTER_DEFAULT_VALUE],
    executedBy: [HISTORY_FILTER_DEFAULT_VALUE],
  });

  const onChangeTab = (tabname: string) => {
    const biTabName = tabname === formsTab ? HomePageTabOptions.FORMS : HomePageTabOptions.HISTORY;
    spyClick(names.HomePage.ChangeTab, biTabName);
    setTab(tabname);
  };

  return (
    <Box
      pos='absolute'
      left={0}
      bottom={animationStage >= AnimationStage.content ? 0 : '-100vh'}
      w='100%'
      h='calc(100% - 100px)'
      bg='gray.1'
      style={{ transitionDuration: `${CONTENT_TRANSITION_DURATION}ms` }}
      className='shadow-[0_10px_10px_-10px_rgba(0,_0,_0,_0.2)_inset] transition-[bottom]'
    >
      <Center pos='absolute' top='0' w='100%' className='-translate-y-1/2 z-20'>
        <SegmentedControl
          data={[formsTab, historyTab]}
          value={tab}
          bg='white'
          classNames={classes}
          color='cool.9'
          radius='xl'
          onChange={onChangeTab}
          data-testid={testIds.tabs}
        />
      </Center>
      {tab === formsTab && <FormsTabContent />}
      {tab === historyTab && <HistoryTabContent filterValues={filterValues} onFilterChange={setFilterValues} />}
    </Box>
  );
};
